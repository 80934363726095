// AuthContext.tsx
import React, { createContext, useState, useContext, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { isLoggedIn } from '../pages/Login/Services/AuthService';

// Define the shape of the context value
interface AuthContextValue {
  isUserLoggedIn: boolean;
  login: () => void;
  logout: () => void;
}

// Create the context
const AuthContext = createContext<AuthContextValue | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<RouteProps> = ({ children }) => {

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = isLoggedIn();
    setIsUserLoggedIn(loggedIn);
  }, []);

  const login = () => {
    console.log('login');
    setIsUserLoggedIn(true);
  };

  const logout = () => {
    console.log('logout');
    setIsUserLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isUserLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
