import { AssessmentType, ReportData } from '../../../../models/AI_Report';  // Importing the data model for the assessment location

// Component for displaying the inspection location in the email report
const ERVehicleInfoSlideImageLocation = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer inpection_location_outer">
            <div className="top_part">
                {/* Display the location icon */}
                <img alt=""
                    src="/images/email_report/location_icon.svg" />
                <p className="m-0">Inspection Location</p> {/* Title for the section */}
            </div>

            {/* Section for displaying the location image */}
            <div className="body_part location_body_part">
                <div className="location_img_outer">
                    {/* Image with a centered location icon */}
                    <div className="centered_div_with_img">
                        <img alt=""
                            src="/images/email_report/location_blue.svg" /> {/* Blue location pin icon */}
                    </div>
                    <img alt=""
                        src="/images/email_report/area_location.png" /> {/* Area location map image */}
                </div>
            </div>

            {/* Bottom section displaying location name and coordinates */}
            <div className="bottom_part">
                {/* Display the location name or 'NA' if not available */}
                <p className="m-0">{reportData?.assessmentType === AssessmentType.CLAIM_SUBMISSION ? reportData?.preferredLocationVO?.location_name : reportData?.assessmentLocation?.location_name || 'NA'}</p>
                {/* Display latitude and longitude or 'NA' if not available */}
                <span>{reportData?.assessmentType === AssessmentType.CLAIM_SUBMISSION ? reportData?.preferredLocationVO?.latitude : reportData?.assessmentLocation?.latitude || 'NA'}, {reportData?.assessmentType === AssessmentType.CLAIM_SUBMISSION ? reportData?.preferredLocationVO?.longitude : reportData?.assessmentLocation?.longitude || 'NA'}</span>
            </div>
        </div>
    )
}

export default ERVehicleInfoSlideImageLocation;
