import { AssessmentType, ReportData } from '../../../../models/AI_Report';  // Importing the data model for the report

// Component for displaying image coverage details in the email report
const ERVehicleInfoSlideCoverage = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer inpection_coverage_outer">
            <div className="top_part">
                {/* Display the icon for image coverage */}
                <img alt=""
                    src="/images/email_report/location_icon.svg" />
                <p className="m-0">Image Coverage</p> {/* Title for the section */}
            </div>

            <div className="body_part coverage_body_part">
                <div className="coverage_body_outer">

                    {/* Left section with car image and circle indicators */}
                    <div className="left_img">
                        <div className="only_car_outer">
                            {/* Display the car image */}
                            <img src="https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/coverage_car.png"
                                alt="" />
                        </div>

                        {/* Circle indicators for front passenger coverage */}
                        <div className="circle_image_box">
                            <img src={(reportData?.assessmentType === AssessmentType.CLAIM_SUBMISSION || reportData?.coverageDetails?.frontPassengerCoverage?.allCaptured)
                                ? "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/frontPassengerCoverage.png"
                                : "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/frontPassengerCoverageNotCaptured.png"}
                                alt="" />
                        </div>

                        {/* Circle indicators for front driver coverage */}
                        <div className="circle_image_box">
                            <img src={(reportData?.assessmentType === AssessmentType.CLAIM_SUBMISSION || reportData?.coverageDetails?.frontDriverCoverage?.allCaptured)
                                ? "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/frontDriverCoverage.png"
                                : "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/frontDriverCoverageNotCaptured.png"}
                                alt="" />
                        </div>

                        {/* Circle indicators for rear passenger coverage */}
                        <div className="circle_image_box">
                            <img src={(reportData?.assessmentType === AssessmentType.CLAIM_SUBMISSION || reportData?.coverageDetails?.rearPassengerCoverage?.allCaptured)
                                ? "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/rearPassengerCoverage.png"
                                : "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/rearPassengerCoverageNotCaptured.png"}
                                alt="" />
                        </div>

                        {/* Circle indicators for rear driver coverage */}
                        <div className="circle_image_box">
                            <img src={(reportData?.assessmentType === AssessmentType.CLAIM_SUBMISSION || reportData?.coverageDetails?.rearDriverCoverage?.allCaptured)
                                ? "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/rearDriverCoverage.png"
                                : "https://carscan-public-files.s3.amazonaws.com/new_report_redesign_files/static/assets/coverage/rearDriverCoverageNotCaptured.png"}
                                alt="" />
                        </div>
                    </div>

                    {/* Right section displaying time and coverage details */}
                    <div className="right_data">
                        <div className="top_data_outer">
                            {/* Start time section */}
                            <div className="data_time_outer">
                                <div className="left_part">
                                    <p className="m-0">Start time:</p> {/* Label for start time */}
                                </div>
                                <div className="right_part">
                                    {/* Display the start time or 'NA' if not available */}
                                    <p className="m-0">{reportData.startTime || 'NA'}</p>
                                </div>
                            </div>

                            {/* End time section */}
                            <div className="data_time_outer">
                                <div className="left_part">
                                    <p className="m-0">End time:</p> {/* Label for end time */}
                                </div>
                                <div className="right_part">
                                    {/* Display the end time or 'NA' if not available */}
                                    <p className="m-0">{reportData.endTime || 'NA'}</p>
                                </div>
                            </div>

                            {/* Duration section */}
                            <div className="data_time_outer">
                                <div className="left_part">
                                    <p className="m-0">Duration:</p> {/* Label for duration */}
                                </div>
                                <div className="right_part">
                                    {/* Display the duration or 'NA' if not available */}
                                    <p className="m-0">{reportData.duration || 'NA'}</p>
                                </div>
                            </div>
                        </div>

                        {/* Bottom section displaying visible and non-visible angles */}
                        <div className="bottom_pointers">
                            <div className="pointer_outer">
                                {/* Icon and label for angles visible */}
                                <img alt=""
                                    src="/images/email_report/agles_visible.png" />
                                <p className="m-0">Angles Visible</p>
                            </div>
                            <div className="pointer_outer">
                                {/* Icon and label for angles not visible */}
                                <img alt=""
                                    src="/images/email_report/angles_not_visible.png" />
                                <p className="m-0">Angles Not Visible</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ERVehicleInfoSlideCoverage;
