import { PinDamagesData } from '../../../../models/AI_Report';  // Importing the data model for pin damages

// Component for displaying pin damages in the email report
const ERVehicleInfoSlidePinDamages = ({ pinDamagesData }: { pinDamagesData: PinDamagesData }) => {
    return (
        <div className="info_body_outer">
            <div className="top_part">
                {/* Display the icon for pin damages */}
                <img alt=""
                    src="/images/email_report/person_settings_icon.svg" />
                <p className="m-0">Pin Damages</p> {/* Title for the section */}
            </div>

            <div className="body_part">
                <div className="pin_damages_outer">
                    {/* Table for displaying the pin damage counts */}
                    <table>
                        <thead>
                            <tr>
                                {/* Column headers for the table */}
                                <th></th>
                                <th>Front Side</th>
                                <th>Driver Side</th>
                                <th>Rear Side</th>
                                <th>Passenger Side</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Row for displaying non-chargeable (green pin) damages */}
                            <tr>
                                <td>
                                    <p className="m-0 _head_text">No. of green pins</p> {/* Label for green pins */}
                                    <p className="m-0 sub_text">(Acceptable marked damages)</p> {/* Description */}
                                </td>
                                {/* Display the non-chargeable (green pin) damage counts for each side */}
                                <td>{pinDamagesData.Front.nonChargeableCount}</td>
                                <td>{pinDamagesData.Driver.nonChargeableCount}</td>
                                <td>{pinDamagesData.Rear.nonChargeableCount}</td>
                                <td>{pinDamagesData.Passenger.nonChargeableCount}</td>
                            </tr>

                            {/* Row for displaying chargeable (red pin) damages */}
                            <tr>
                                <td>
                                    <p className="m-0 _head_text">No. of red pins</p> {/* Label for red pins */}
                                    <p className="m-0 sub_text">(Chargeable marked damages)</p> {/* Description */}
                                </td>
                                {/* Display the chargeable (red pin) damage counts for each side */}
                                <td>{pinDamagesData.Front.chargeableCount}</td>
                                <td>{pinDamagesData.Driver.chargeableCount}</td>
                                <td>{pinDamagesData.Rear.chargeableCount}</td>
                                <td>{pinDamagesData.Passenger.chargeableCount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ERVehicleInfoSlidePinDamages;
