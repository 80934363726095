import React, { useState } from 'react'; // Import React and useState hook for managing component state
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'; // Import Material UI components for Accordion
// import '../../Email_Report/Email_Report.scss'; // Import the SCSS for styling
import '../SCSS/email_report_QnA.scss'; // Importing styles for Email Report QnA

// Define the structure for Answer, which includes a question and an array of answers
interface Answer {
    question: string; // The question string
    answers: string[]; // Array of answers (in this case, we assume there's at least one answer)
}

// Define the props structure for the component, which accepts a QnA array
interface QnAProps {
    QnA: Answer[]; // Array of question and answer pairs
}

// Functional component to display a list of questions and answers in an accordion
export default function EmailReportQnA({ QnA }: QnAProps) {
    // State to track whether the accordion is expanded or collapsed
    const [expanded, setExpanded] = useState(false);

    // Function to toggle the accordion expansion state
    const handleToggle = () => {
        setExpanded(!expanded); // Toggle the expanded state
    };

    return (
        // Accordion component for showing the QnA section
        <Accordion expanded={expanded} onChange={handleToggle} className='accordian_body_outer'>
            {/* Accordion summary (header) with a toggle button */}
            <AccordionSummary
                expandIcon={<p>{expanded ? 'Hide' : 'View'}</p>} // Toggle text based on expansion state
                aria-controls="QnS_content" // Accessibility attribute
                id="QnS_header" // Unique ID for the header
                className='accordian_summary_outer'
            >
                View Questionnaire {/* Text displayed in the accordion header */}
            </AccordionSummary>

            {/* Accordion details (content) containing the QnA list */}
            <AccordionDetails className='accordian_details_outer'>
                {/* Loop through the QnA array and display each question and its first answer */}
                {QnA.map((item, index) => (
                    <div className="q_n_a_outer" key={index}> {/* Outer container for each QnA pair */}
                        <div className="qstn">
                            <p className="m-0">{item?.question}</p> {/* Display the question */}
                        </div>
                        <div className="ans">
                            {item?.answers[0]?.includes('data:') ?
                                <img src={item?.answers[0]} />
                                :
                                <p className="m-0">{item?.answers[0]}</p>
                            }
                        </div>
                    </div>
                ))}
            </AccordionDetails>
        </Accordion>
    );
}
