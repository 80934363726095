import Cookies from "js-cookie";
import {
  IConfigurationProps,
  IFeatureConfigurationButtonProps,
  IFeatureProps,
  ISubmenu,
  ThemeMode,
} from "../constants/dataTypes";
import { isLoggedIn } from "../pages/Login/Services/AuthService";
import {
  FEATURE_CONFIGURATION_BUTTON_KEY,
  ThemeType,
  storageKey,
} from "../constants/constants";
import { Configurations } from "../constants/menuConfig";
import { LocalStorageService } from "./LocalStorage";
import { NavigateFunction } from "react-router-dom";

export const getTheme = (): keyof ThemeMode => {
  const initialTheme: keyof ThemeMode = isLoggedIn()
    ? (Cookies.get(storageKey.theme) as keyof ThemeMode) || ThemeType.light
    : ThemeType.light;
  return initialTheme;
};

export const isDark = (): boolean => {
  const initialTheme: keyof ThemeMode = isLoggedIn()
    ? (Cookies.get(storageKey.theme) as keyof ThemeMode) || ThemeType.light
    : ThemeType.light;
  return initialTheme === ThemeType.dark;
};

export const getSnackbarMessage = (
  error?: any,
  customMessage?: string
): string => {
  let message: string = error?.response?.data?.error_message || "";
  if (customMessage) message = customMessage;
  if (error && !message) {
    switch (error.status) {
      case 500:
        message = "Internal Server Error ";
        break;
      case 502:
      case 503:
        message = "Server unavailble temporary ";
        break;
      case 401:
      case 403:
        message = "UNAUTHORIED";
        break;
      case 400:
        message = "BAD REQUEST ";
        break;
      case 404:
        message = "NOT FOUND ";
        break;
    }
  }
  if (message === "")
    message = "Something went wrong, please try after sometime ";
  return message;
};

export const getListOfFeatures = (id: string): IFeatureProps[] => {
  let list_of_features: IFeatureProps[] =
    Configurations?.find((e: IConfigurationProps) => {
      return e?.id === id;
    })?.features || [];

  return list_of_features;
};

export const getListOfSubmenuFeatures = (
  id: string,
  subId: string
): IFeatureProps[] => {
  const configuration: IConfigurationProps | undefined = Configurations?.find(
    (submenu: IConfigurationProps) => submenu.id === id
  );

  if (!configuration) {
    return [];
  }

  const submenu: ISubmenu | undefined = configuration.submenu?.find(
    (sub: ISubmenu) => sub.id === subId
  );

  return submenu?.features || [];
};

export const setCompanySettings = (data: any) => {
  LocalStorageService.removeItem(storageKey.company_settings);
  LocalStorageService.setItem(storageKey.company_settings, data);
};

export const getCompanySettings = (): any => {
  return LocalStorageService.getItem(storageKey.company_settings);
};

export const saveCompanySettings = (newSettings: any[]) => {
  LocalStorageService.setItem(storageKey.company_settings, newSettings);
};

export const silentlyReloadTheUI = (navigate: NavigateFunction) => {
  const currentPath = window.location.pathname;
  navigate("/temporary_route", { replace: true });
  setTimeout(() => {
    navigate(currentPath, { replace: true });
  }, 0);
};

export const getCompanyID = (): string => {
  return getCompanySettings()?.company_id || "";
};

export const getJourneyType = (): [] => {
  return getCompanySettings()?.assessment_types[0].type;
};

interface AssessmentType {
  type: string;
  labeller: { assign: boolean };
}

export const getAssessmentGoingToLabellersTeam = (
  companySettingState: any,
  selectedJourneyType: string
): boolean => {
  if (companySettingState && companySettingState.assessment_types) {
    const assessmentType: AssessmentType | undefined =
      companySettingState.assessment_types.find(
        (type: any) =>
          type.type.toUpperCase() === selectedJourneyType.toUpperCase()
      );

    if (assessmentType) {
      return assessmentType.labeller.assign;
    }
  }

  return false;
};

export const getCarModelDetailsForBothJourney = (
  companySettingState: any
): any => {
  return (
    companySettingState?.dashboard_config?.edit_config
      ?.car_model_edit_configurations || []
  );
};

export const getProductConfigs = (companySettingState: any): any => {
  return companySettingState?.product_configs || [];
};

export const getEditDynamicLinkData = (companySettingState: any): any => {
  const dynamicLinkConfig =
    companySettingState?.dashboard_config?.dynamic_link_config
      ?.dynamic_link_configurations || [];

  const filteredData = dynamicLinkConfig.map((item: any, index: number) => ({
    id: index,
    keyName: item.keyName,
    value: item.label,
    name: item.name,
    selectedOption: item.validators.required ? "mandatory" : "optional",
    isDefault: item.is_default !== undefined ? item.is_default : false,
  }));
  return filteredData;
};

export const getEditDynamicLinkDataForPayload = (
  companySettingState: any
): any => {
  return (
    companySettingState?.dashboard_config?.dynamic_link_config
      ?.dynamic_link_configurations || []
  );
};

export const getCompanyOTP = (): string => {
  return getCompanySettings()?.otp || "";
};

export const getCompanyNameOTP = (): {
  companyName: string;
  companyOTP: string;
} => {
  const companyName = getCompanySettings()?.company_name || "";
  const companyOTP = getCompanySettings()?.otp || "";
  return { companyName, companyOTP };
};

export interface IIsPurchasedDataProps {
  data: any;
  name: string;
  platform?: string;
  featureType?: string;
  eventType?: string;
}

export function getIsPurchased(props: IIsPurchasedDataProps): boolean {
  const { data, name, platform, featureType, eventType } = props;
  const feature = data.features_purchased.find((f: any) => f.name === name);
  if (!feature) {
    return false;
  }
  if (feature.is_enabled === false) {
    return false;
  }
  if (platform && feature.platform && !feature.platform.includes(platform)) {
    return false;
  }
  if (
    featureType &&
    feature.feature_type &&
    !feature.feature_type.includes(featureType)
  ) {
    return false;
  }
  if (eventType && feature.event_type && feature.event_type !== eventType) {
    return false;
  }
  return true;
}

export const setFeatureListDynamically = (
  companySettingState: any,
  selectedJourneyType: string,
  listToSet: any,
  ListSetterFunction: any,
  forceRenderSetter?: any,
  platform?: string,
  eventType?: string
) => {
  const newProductConfig = companySettingState?.product_configs || [];
  const data: any = newProductConfig?.find((e: any) => {
    return e.assessment_type === selectedJourneyType;
  });
  if (data) {
    forceRenderSetter(false);
    const updatedFeatures = listToSet.map((feature: any) => ({
      ...feature,
      isPurchased: getIsPurchased({
        data: data,
        name: feature.id,
        platform: platform,
        featureType: feature.featureType,
      }),
    }));

    ListSetterFunction(updatedFeatures);
  } else {
    console.log("Data not found for selected button:", selectedJourneyType);
  }
};

export const updateScoringData = (
  companySettingState: any,
  selectedJourneyType: string,
  listToUpdate: any[],
  setterFunction: React.Dispatch<React.SetStateAction<any[]>>,
  forceRenderSetter?: any,
  eventType?: string
) => {
  const newProductConfig = getProductConfigs(companySettingState);
  const data: any = newProductConfig?.find(
    (e: any) => e.assessment_type === selectedJourneyType
  );
  if (data) {
    forceRenderSetter(false);
    const updatedList = listToUpdate.map((item) => ({
      ...item,
      isPurchased: getIsPurchased({
        data: data,
        name: item.name,
        featureType: item.featureType,
      }),
    }));

    setterFunction(updatedList);
  } else {
    console.log("No data found for scoring");
  }
};

export const getJourneyTypeArray = (
  FeatureCofigurationsButton: IFeatureConfigurationButtonProps[],
  selectedJourneyType: string,
  id?: string
): any => {
  const type = FeatureCofigurationsButton?.find(
    (e: IFeatureConfigurationButtonProps) => {
      return e?.key === selectedJourneyType;
    }
  )?.key;
  return [type];
};

export const handleButton = (
  id: string,
  setSelectedJourneyType: any,
  localStorageKey: string
) => {
  setSelectedJourneyType(id);
  localStorage.setItem(localStorageKey, id);
};

export const default_selected_mode = (
  FeatureConfigurationsButton: IFeatureConfigurationButtonProps[],
  selectedJourneyType: string,
  setSelectedJourneyType: any,
  localStorageKey: string
) => {
  const selectedMode = FeatureConfigurationsButton?.find(
    (e: IFeatureConfigurationButtonProps) => {
      return e?.key === FEATURE_CONFIGURATION_BUTTON_KEY.PRE_INSPECTION;
    }
  )?.key;

  if (selectedMode && !selectedJourneyType) {
    setSelectedJourneyType(selectedMode);
    localStorage.setItem(localStorageKey, selectedMode);
  }
};

interface IAssessment {
  type: string;
  areas: string[];
}
interface ButtonStatus {
  claim: boolean;
  preInspection: boolean;
}

export const disableJourneyType = (): any => {
  const companySettings = getCompanySettings()?.assessment_types;
  let buttons: ButtonStatus = {
    claim: false,
    preInspection: false,
  };
  companySettings.forEach((assessment: IAssessment) => {
    if (assessment.type === FEATURE_CONFIGURATION_BUTTON_KEY.CLAIM_SUBMISSION) {
      buttons.claim = true;
    } else if (
      assessment.type === FEATURE_CONFIGURATION_BUTTON_KEY.PRE_INSPECTION
    ) {
      buttons.preInspection = true;
    }
  });
  return buttons;
};
