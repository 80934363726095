import { MENUCONFIG_ID } from "../constants/constants";
import instance from "../utils/interceptor";
import { endpoint } from "./constants";

const api = {
  login: (payload: {}) => {
    const url = endpoint.login;
    return instance.post(url, payload);
  },

  refreshTokenAccess: (refreshTokenValue: any, userId: any) => {
    const url = endpoint.refresh_token;
    return instance.post(url, {
      refresh_token: refreshTokenValue,
      user_id: userId,
    });
  },

  getTokeDetails: () => {
    const url = endpoint.token_details;
    return instance.get(url);
  },
  getClientsList: () => {
    const url = endpoint.fetch_clients;
    return instance.get(url);
  },
  getCompanyDetails: (otp: string) => {
    const url = endpoint.fetch_company_details(otp);
    return instance.get(url);
  },

  enable_disable_number_plate_masking_and_OCRreading_through_numberplate: (
    companyId: string,
    payload: any
  ) => {
    const url =
      endpoint.enable_disable_number_plate_masking_and_OCRreading_through_numberplate(
        companyId
      );
    return instance.post(url, payload);
  },

  enable_disable_hydranet_whatsapp_mobile: (
    companyId: string,
    payload: any
  ) => {
    const url = endpoint.enable_disable_hydranet_whatsapp_mobile(companyId);
    return instance.post(url, payload);
  },

  enable_disable_assessment_labeller_team: (
    companyId: string,
    switchLabelerFlow: boolean,
    payload: any
  ) => {
    const url = endpoint.enable_disable_assessment_labeller_team(
      companyId,
      switchLabelerFlow
    );
    return instance.post(url, payload);
  },

  // enable_disable_vehicle_scoring_internal: (
  //   companyId: string,
  //   calculatedVehicleScoring: boolean,
  //   isInterior: boolean,
  //   isExterior: boolean,
  //   payload: any
  // ) => {
  //   const url = endpoint.enable_disable_vehicle_scoring_internal(
  //     companyId,
  //     calculatedVehicleScoring,
  //     isInterior
  //   );
  //   return instance.post(url, payload);
  // },

  // enable_disable_vehicle_scoring_external: (
  //   companyId: string,
  //   calculatedVehicleScoring: boolean,
  //   isExterior: boolean,
  //   payload: any
  // ) => {
  //   const url = endpoint.enable_disable_vehicle_scoring_external(
  //     companyId,
  //     calculatedVehicleScoring,
  //     isExterior
  //   );
  //   return instance.post(url, payload);
  // },

  enable_disable_vehicle_scoring: (
    companyId: string,
    togglevalue: boolean,
    scoring_id: string,
    payload: any
  ) => {
    let url: string = "";
    if (scoring_id === MENUCONFIG_ID.INTERNAL_SCORING) {
      url = endpoint.enable_disable_vehicle_scoring_internal(
        companyId,
        togglevalue
      );
    } else if (scoring_id === MENUCONFIG_ID.EXTERNAL_SCORING) {
      url = endpoint.enable_disable_vehicle_scoring_external(
        companyId,
        togglevalue
      );
    }
    return instance.post(url, payload);
  },

  access_super_admin_dashboard: (companyId: string, payload: any) => {
    const url = endpoint.access_super_admin_dashboard(companyId);
    return instance.post(url, payload);
  },

  enable_disable_GT_fusion_link_automation: (
    companyId: string,
    gtConfiguration: boolean,
    payload: any
  ) => {
    const url = endpoint.enable_disable_GT_fusion_link_automation(
      companyId,
      gtConfiguration
    );
    return instance.post(url, payload);
  },

  edit_car_model_details: (companyId: string, payload: any) => {
    const url = endpoint.edit_car_model_details(companyId);
    return instance.post(url, payload);
  },

  send_dynamic_link: (companyId: string, payload: any) => {
    const url = endpoint.send_dynamic_link(companyId);
    return instance.post(url, payload);
  },
};

export default api;
