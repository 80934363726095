import { ReportData } from '../../../../models/AI_Report';  // Importing the data model for the report

// Component for displaying the vehicle status in the email report
const ERVehicleInfoSlideVehicleStatus = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer">
            <div className="top_part">
                {/* Display the icon for vehicle status */}
                <img
                    alt=""
                    src="/images/email_report/car_info.svg"
                />
                <p className="m-0">Vehicle Status</p> {/* Title for the section */}
            </div>

            <div className="body_part">
                <div className="data_part_container">
                    {/* Roadworthy Status */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Road Worthy:</p> {/* Label for roadworthy status */}
                        </div>
                        <div className="right_part">
                            {/* Display the roadworthy status or 'NA' if not available */}
                            <p className="m-0">{reportData?.saintAPIDetails?.Roadworthy || 'NA'}</p>
                        </div>
                    </div>

                    {/* Roadworthy Date */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Roadworthy Date:</p> {/* Label for roadworthy date */}
                        </div>
                        <div className="right_part">
                            {/* Display the roadworthy date or 'NA' if not available */}
                            <p className="m-0">{reportData?.saintAPIDetails?.RoadworthyDate || 'NA'}</p>
                        </div>
                    </div>

                    {/* Registration Date */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Registration Date:</p> {/* Label for registration date */}
                        </div>
                        <div className="right_part">
                            {/* Display the registration date or 'NA' if not available */}
                            <p className="m-0">{reportData?.saintAPIDetails?.RegistrationDate || 'NA'}</p>
                        </div>
                    </div>

                    {/* Warranty Status */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Warranty Status:</p> {/* Label for warranty status */}
                        </div>
                        <div className="right_part">
                            {/* Display the warranty status based on a boolean value */}
                            <p className="m-0">
                                {!reportData?.saintAPIDetails?.InWarranty ? 'NA' :
                                    (reportData?.saintAPIDetails?.InWarranty === 'true' ? 'In Warranty' : 'Not In Warranty')}
                            </p>
                        </div>
                    </div>

                    {/* Odometer Reading */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Odometer Reading:</p> {/* Label for odometer reading */}
                        </div>
                        <div className="right_part">
                            {/* Display the odometer reading or 'NA' if not available */}
                            <p className="m-0">{reportData?.odometerValue || 'NA'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ERVehicleInfoSlideVehicleStatus;
