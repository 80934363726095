import { ReportData } from '../../../../models/AI_Report';  // Importing the data model for the report

// Component for displaying assessment details in the email report
const ERVehicleInfoSlideAssessmentDetails = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer">
            <div className="top_part">
                {/* Display the icon for assessment details */}
                <img alt=""
                    src="/images/email_report/person_settings_icon.svg" />
                <p className="m-0">Assessment Details</p> {/* Title for the section */}
            </div>

            <div className="body_part">
                <div className="data_part_container">

                    {/* Type of assessment section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Type:</p> {/* Label for assessment type */}
                        </div>
                        <div className="right_part">
                            {/* Display the assessment type or 'NA' if not available */}
                            <p className="m-0">{reportData?.assessmentType?.replace('_', ' ') || 'NA'}</p>
                        </div>
                    </div>

                    {/* Policy Number section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Policy No. :</p> {/* Label for policy number */}
                        </div>
                        <div className="right_part">
                            {/* Display the policy number (fieldTwo) or 'NA' if not available */}
                            <p className="m-0">{reportData?.fieldTwo || 'NA'}</p>
                        </div>
                    </div>

                    {/* Date Requested section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Date Requested. :</p> {/* Label for date requested */}
                        </div>
                        <div className="right_part">
                            {/* Display the date requested (createdDateTime) or 'NA' if not available */}
                            <p className="m-0">{reportData?.createdDateTime || 'NA'}</p>
                        </div>
                    </div>

                    {/* Contact Number section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Contact No. :</p> {/* Label for contact number */}
                        </div>
                        <div className="right_part">
                            {/* Display 'NA' for the contact number */}
                            <p className="m-0">NA</p>
                        </div>
                    </div>

                    {/* AutoVal Reference Number section */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">AutoVal Ref No :</p> {/* Label for AutoVal reference number */}
                        </div>
                        <div className="right_part">
                            {/* Display the AutoVal reference number (id) or 'NA' if not available */}
                            <p className="m-0">{reportData?.id || 'NA'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ERVehicleInfoSlideAssessmentDetails;
