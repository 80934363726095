import '../SCSS/email_report_side_wise_view.scss';  // Importing the SCSS file for styling the component

// Importing necessary components from Material UI for accordion, buttons, and icons
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react'; // Importing React hooks
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // Icon for the "previous" button
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // Icon for the "next" button
import { DamageArea, DamageSubArea, SeverityLabel } from '../../../models/AI_Report'; // Importing models

// Defining severity levels with corresponding CSS classes and labels
const severityLevels = [
    { className: "light_green_ball", label: SeverityLabel.VERY_SMALL },
    { className: "deep_green_ball", label: SeverityLabel.SMALL },
    { className: "yellow_ball", label: SeverityLabel.MEDIUM },
    { className: "orange_ball", label: SeverityLabel.SEVERE },
    { className: "red_ball", label: SeverityLabel.CRITICAL },
    { className: "no_damage_ball", label: SeverityLabel.NO_DAMAGE }
];

// Props interface for the component
interface EmailReportCarSideWiseViewProps {
    vehicleDamageInfographics: DamageArea[]; // Array of damage areas for the vehicle
}

// Main functional component to display vehicle side-wise damage infographics
const EmailReportCarSideWiseView = ({ vehicleDamageInfographics }: EmailReportCarSideWiseViewProps) => {
    // State to track the expansion of the accordion
    const [expanded, setExpanded] = useState(false);
    // State to track the currently selected index of damage area
    const [currentIndex, setCurrentIndex] = useState(0);
    // State to manage fade-in effect
    const [fadeIn, setFadeIn] = useState(false);

    // Fetching the currently selected data
    const selectedData = vehicleDamageInfographics[currentIndex];
    const prevIndex = (currentIndex === 0) ? vehicleDamageInfographics.length - 1 : currentIndex - 1;
    const nextIndex = (currentIndex === vehicleDamageInfographics.length - 1) ? 0 : currentIndex + 1;
    const buttonGroupRef: React.RefObject<HTMLButtonElement> = useRef(null); // Ref for button elements


    // Function to scroll to the selected button
    const scrollToButton = (index: number) => {
        const buttonGroup = buttonGroupRef.current;
        const selectedButton = buttonGroup?.querySelectorAll('button')[index];

        if (selectedButton && buttonGroup) {
            // Scroll to the selected button smoothly
            const buttonOffsetLeft = selectedButton.offsetLeft;
            const buttonWidth = selectedButton.offsetWidth;

            // Scroll the button group to make the selected button visible in the middle
            buttonGroup.scrollTo({
                left: buttonOffsetLeft - (buttonGroup.offsetWidth / 2 - buttonWidth / 2),
                behavior: 'smooth', // Smooth scroll effect
            });
        }
    };

    // Scroll to the selected button whenever the selectedData changes
    useEffect(() => {
        const index = vehicleDamageInfographics.findIndex((slide) => slide.areaFormatted === selectedData.areaFormatted);
        if (index !== -1) {
            setCurrentIndex(index); // Update current index
            scrollToButton(index); // Scroll to the selected button
        }
    }, [selectedData, vehicleDamageInfographics]);



    // Function to toggle the accordion expansion
    const handleToggle = () => {
        setExpanded(!expanded);
    };

    // Function to change the selected side based on user selection (dropdown or button)
    const handleChange = (side: string) => {
        const index = vehicleDamageInfographics.findIndex((item) => item.areaFormatted === side);
        if (index !== -1) {
            setFadeIn(false);  // Trigger fade-out effect
            setTimeout(() => {
                setCurrentIndex(index);  // Update the selected index
                setFadeIn(true);  // Trigger fade-in effect
            }, 300);  // Delay matches the transition timing for smooth change
        }
    };

    // Function to move to the next side
    const handleNext = () => {
        setFadeIn(false);  // Trigger fade-out effect
        setTimeout(() => {
            setCurrentIndex(nextIndex); // Loop through sides
            setFadeIn(true);  // Trigger fade-in effect
        }, 300);  // Delay matches the transition timing
    };

    // Function to move to the previous side
    const handlePrev = () => {
        setFadeIn(false);  // Trigger fade-out effect
        setTimeout(() => {
            setCurrentIndex(prevIndex);  // Move to previous index or loop around
            setFadeIn(true);  // Trigger fade-in effect
        }, 300);  // Delay matches the transition timing
    };

    // Function to get the corresponding severity class name based on the severity label
    const getSeverityClassName = (severity: string) => {
        const severityObj = severityLevels.find(level => level.label === severity);
        return severityObj ? severityObj.className : "no_damage_ball";  // Return appropriate class or default
    };

    // UseEffect hook to handle the fade-in effect when the index changes
    useEffect(() => {
        setFadeIn(true);  // Start fade-in when index changes
    }, [currentIndex]);

    // Variables to handle touch events for swipe gestures
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);

    // Function to handle the start of a touch gesture (for swipe)
    const handleTouchStart = (event: any) => {
        touchStartX.current = event.touches ? event.touches[0].clientX : event.clientX; // Track start position
    };

    // Function to handle movement during the touch gesture (for swipe)
    const handleTouchMove = (event: any) => {
        touchEndX.current = event.touches ? event.touches[0].clientX : event.clientX; // Track movement
    };

    // Function to handle the end of a touch gesture and trigger swipe actions
    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 100) {
            handleNext(); // Swipe left for next
        }
        if (touchStartX.current - touchEndX.current < -100) {
            handlePrev(); // Swipe right for previous
        }
    };

    return (
        <Accordion expanded={expanded} onChange={handleToggle} className='accordian_body_outer'>
            {/* Accordion header section */}
            <AccordionSummary
                expandIcon={<p>{expanded ? 'Minimise' : 'View'}</p>} // Toggle text
                aria-controls="Side_Wise_View_content"
                id="Side_Wise_View_header"
                className='accordian_summary_outer'
            >Vehicle Damage & Impacts Infographics
            </AccordionSummary>

            {/* Accordion details section */}
            <AccordionDetails className='accordian_details_outer'>

                {/* Dropdown for selecting vehicle side */}
                <Select
                    value={selectedData.areaFormatted}
                    onChange={(event) => handleChange(event.target.value)}  // Trigger side change on select
                    displayEmpty
                    className='side_selection_dropdown'
                >
                    {vehicleDamageInfographics.map((side) => (
                        <MenuItem key={side.areaFormatted} value={side.areaFormatted}>
                            {side.areaFormatted} {/* Display vehicle side names */}
                        </MenuItem>
                    ))}
                </Select>

                {/* Toggle buttons for selecting vehicle sides */}
                <div className="side_selection_button_toggle_wrapper">
                    <ToggleButtonGroup
                        ref={buttonGroupRef}
                        value={selectedData.areaFormatted}
                        exclusive
                        onChange={(event, newValue) => {
                            if (newValue !== null) {
                                handleChange(newValue); // Handle button toggle
                            }
                        }}
                        aria-label="Vehicle sides"
                        className='side_selection_button_toggle'
                    >
                        {vehicleDamageInfographics.map((side) => (
                            <ToggleButton key={side.areaFormatted} value={side.areaFormatted}>
                                {side.areaFormatted} {/* Vehicle side toggle buttons */}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </div>

                {/* Section for displaying vehicle damage details */}
                <div className={`side_wise_data_view_outer ${fadeIn ? 'fade-in' : ''}`}>
                    <div className={`damage_type_data_outer ${fadeIn ? 'fade-in' : ''}`}
                        onTouchStart={handleTouchStart}  // Track swipe start
                        onTouchMove={handleTouchMove}  // Track swipe movement
                        onTouchEnd={handleTouchEnd}  // Handle swipe action
                        onMouseDown={handleTouchStart}  // Track mouse down for swipe
                        onMouseMove={handleTouchMove}  // Track mouse movement for swipe
                        onMouseUp={handleTouchEnd}  // Handle mouse up (end of swipe)
                    >
                        <div className="left_part">
                            {selectedData?.claimed &&
                                <div className="claimed_text">
                                    <p className="m-0">Claimed Part</p> {/* Display claimed part text */}
                                </div>
                            }
                            <div className="left_part_inner">
                                <div className="left_part_inner_wrapper">
                                    <IconButton onClick={handlePrev} aria-label="previous" className='icon_outer prev_icon_outer'>
                                        <ChevronLeftIcon className='prev_next_icon' /> {/* Previous button */}
                                    </IconButton>
                                    <IconButton onClick={handleNext} aria-label="next" className='icon_outer next_icon_outer'>
                                        <ChevronRightIcon className='prev_next_icon' /> {/* Next button */}
                                    </IconButton>



                                    {/* Main image for the selected damage area */}
                                    <img src={selectedData.damageAreaLogoPNGUrl} alt={`${selectedData.areaFormatted} image`} className='main_part_image_with_zero_opacity' />


                                    {/* This image for height adjustment - opacity zero */}
                                    <img src={selectedData.damageAreaLogoPNGUrl} alt={`${selectedData.areaFormatted} image`} />


                                    {/* Display sub-area highlights if available */}
                                    {selectedData.damageSubAreasAndTypesAndSeverities.map((part: DamageSubArea, index: number) => (
                                        part.subAreaHighLightUrl && (
                                            <img src={part.subAreaHighLightUrl} key={index} alt={`${part.subAreaFormatted} highlight`} />
                                        )
                                    ))}


                                </div>
                                <p>{selectedData.areaFormatted}</p> {/* Display area name */}
                            </div>
                        </div>

                        {/* Table to display damage details */}
                        <div className="right_data_part">
                            <table>
                                <thead>
                                    <tr>
                                        <th className='claim_icon_col'></th>
                                        <th>Parts name</th>
                                        <th>Severity</th>
                                        <th>Damage type</th>
                                        <th className='repairer_replace_col'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedData.damageSubAreasAndTypesAndSeverities.map((part: DamageSubArea, index: number) => (
                                        <tr key={index}>
                                            <td className='claim_icon_col'>
                                                {part?.claimed && <img alt="" src="/images/email_report/claimed_icon.svg" />}
                                            </td>
                                            <td>
                                                <p className="m-0">{part.subAreaFormatted}</p>
                                            </td>
                                            <td>
                                                <div className={`severity_ball ${getSeverityClassName(part.damageSeverity)}`}></div>
                                            </td>
                                            <td>{part.damageTypes ? part.damageTypes : 'No Damage'}</td>
                                            <td className='repairer_replace_col'>
                                                {part?.operation &&
                                                    <img src={part?.operation === "Repair" ? "/images/email_report/repair_tag.svg" : part?.operation === "Replace" ? "/images/email_report/replace_tag.svg" : ''} alt="" />}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Rounded buttons to switch between vehicle sides */}
                <div className="rounded_clickable_button_container">
                    {vehicleDamageInfographics.map((side) => (
                        <Tooltip key={side.areaFormatted} title={side.areaFormatted} arrow>
                            <IconButton
                                key={side.areaFormatted}
                                type="button"
                                onClick={() => handleChange(side.areaFormatted)}
                                className={side.areaFormatted === selectedData.areaFormatted ? "selectedSideRoundedElement" : ''}
                            />
                        </Tooltip>
                    ))}
                </div>

                {/* Display severity levels as highlighters */}
                <div className="damage_highlighters_outer">
                    {severityLevels.map((severity, index) => (
                        <div className="highlighter_inner" key={index}>
                            <div className={`severity_ball ${severity.className}`}></div> {/* Display severity ball */}
                            <div className="severity_name">
                                <p className="m-0">{severity.label}</p> {/* Display severity label */}
                            </div>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default EmailReportCarSideWiseView;
