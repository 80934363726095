export const endpoint = {
  login: `${process.env.REACT_APP_BASE_SERVER_URL}/api/authenticate/login`,
  token_details: `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/common/token-details`,
  refresh_token: `${process.env.REACT_APP_BASE_SERVER_URL}/api/refresh`,
  fetch_clients: `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/assessments/all-companies`,
  fetch_company_details: (otp: string) => {
    return `${process.env.REACT_APP_CARDATA_BASE_SERVICE_URL}/companies/${otp}/settings`;
  },
  enable_disable_number_plate_masking_and_OCRreading_through_numberplate: (
    companyId: string
  ) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/enable-skip-numberplate-masking`;
  },
  enable_disable_hydranet_whatsapp_mobile: (companyId: string) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/switch-hydra-net`;
  },
  enable_disable_assessment_labeller_team: (
    companyId: string,
    switchLabelerFlow: boolean
  ) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/switch-ai-labeler-flows?switch-ai-flow=false&switch-labeler-flow=true`;
  },
  enable_disable_vehicle_scoring_internal: (
    companyId: string,
    togglevalue: boolean
  ) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/vehicle-scoring?calculate-vehicle-score=${togglevalue}&is_interior=true&is_exterior=false`;
  },
  enable_disable_vehicle_scoring_external: (
    companyId: string,
    togglevalue: boolean
  ) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/vehicle-scoring?calculate-vehicle-score=${togglevalue}&is_exterior=true&is_interior=false`;
  },
  access_super_admin_dashboard: (companyId: string) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/create-super-admin`;
  },
  enable_disable_GT_fusion_link_automation: (
    companyId: string,
    gtConfiguration: boolean
  ) => {
    //return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/gt-configuration?disable-gt=false`;
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/gt-configuration?disable-gt=${gtConfiguration}`;
  },

  edit_car_model_details: (companyId: string) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/upsert-car-model-detail-fields`;
  },

  send_dynamic_link: (companyId: string) => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/product-config/${companyId}/upsert-send-dynamic-link-fields`;
  },
  // EMAIL REPORT
  get_ai_report: (id: string) => `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/public/assessments/${id}/final-ai-report-model?output_type=JSON`,
  generate_new_token: (id: string, jwt: string) => `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/public/assessments/${id}/refresh-jwt?jwt=${jwt}`,
  download_ai_report: (id: string) => `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/assessments/download/pdf/s3-link/final-ai-report-with-estimates/assessments/${id}?refreshReport=true`,
  // download_ai_report: (id: string) => `https://api.qaone.carscan.ai/coreservice/assessments/download/pdf/s3-link/final-ai-report-with-estimates/assessments/${id}?refreshReport=false`,
  // https://api.qaone.carscan.ai/coreservice/assessments/download/pdf/s3-link/final-ai-report-with-estimates/assessments/3f07f4b9-d1d9-4467-bdf0-4e6d2a89d02b?refreshReport=true
};
