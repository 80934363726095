// Route parameters interface, typically used to extract parameters from URL
export type RouteParams = {
  assessment_id: string | undefined;
};

// Interface representing details about a sub-area of damage
export interface DamageSubArea {
  subArea: string;
  subAreaHighLightUrl: string | null;
  subAreaFormatted: string;
  damageTypes: string;
  preDamageTypes: string | null;
  postDamageTypes: string | null;
  prePostDamageTypes: string | null;
  preInsDamaged: boolean;
  postInsDamaged: boolean;
  damageSeverity: string;
  damageSeverityCssClass: string;
  claimed: boolean;
  operation: string | null;
}

// Interface for representing a larger damage area composed of sub-areas
export interface DamageArea {
  area: string;
  areaFormatted: string;
  damageAreaLogoPNGUrl: string;
  damageSubAreasAndTypesAndSeverities: DamageSubArea[];
  claimed: boolean;
}

// Interface representing damage areas for different sections of the vehicle
export interface SectionWiseDamageAreaList {
  assessmentFiles: AssessmentFile[];
  section: string;
  damageAreas: DamageArea[];
  sectionWiseRepairParts: string[];
  sectionWiseReplaceParts: string[];
}

// Interface representing an assessment file, including image and damage data
export interface AssessmentFile {
  label: string;
  highestDamageSeverityAndDamageType: string | null;
  averageAiConfidenceLevel: string | null;
  averageAiConfidenceLevelCssClass: string | null;
  imageURL: string;
  imageClass: string | null;
  additionalInfo: string | null;
  claimed: boolean;
  reflected: boolean;
  operation: string | null;
  condition: string | null;
  pinnedDamages: DamageCoordinate[];
  minorDamageCoOrdinates: DamageCoordinate[];
  damageCoOrdinates: DamageCoordinate[];
  boundingBoxDrawn: boolean;
  imageWithMinorDamage?: string;
  imageWithPinDamage?: string;
  imageWithBothDamages?: string;
  isLoadingImage?: boolean;
  damageTypes: string[];
  imageWithBbox: string;
}

// Interface for damage coordinates (bounding box)
export interface DamageCoordinate {
  left: string;
  bottom: string;
  top: string;
  right: string;
}

// Interface for representing assessment files by section
export interface AssessmentFilesSectionWiseList {
  section: string;
  assessmentFiles: AssessmentFile[];
}

// Interface for answers to assessment questions
export interface Answer {
  answers: string[];
  dashboard_question_type: string | null;
  data_type: string | null;
  db_column: string | null;
  max_selected_repairers: number | null;
  options: string[];
  place_holder_label: string | null;
  question: string;
  question_id: string;
  type: string;
}

// Interface for vehicle image coverage details
export interface CoverageDetails {
  frontPassengerCoverage: {
    allCaptured: boolean;
  };
  frontDriverCoverage: {
    allCaptured: boolean;
  };
  rearDriverCoverage: {
    allCaptured: boolean;
  };
  rearPassengerCoverage: {
    allCaptured: boolean;
  };
}

// Interface for GT Estimate details
export interface GTEstimateDetails {
  gtEstimateValue: string;
  gtEstimate: string;
}

// Interface for counting pinned damages
export interface PinCount {
  chargeableCount: number;
  nonChargeableCount: number;
}


// Main report data interface
export interface ReportData {
  id: string;
  createdDateTime: string;
  reportCreatedDate: string;
  reportCreatedTime: string;
  createdDate: string;
  createdTime: string;
  startTime: string;
  startDate: string;
  endTime: string;
  duration: string;
  licenceNumber: string;
  registrationNumber: string;
  engineNumber: string;
  vinNo: string;
  make: string;
  model: string;
  color: string | null;
  glassFitment: string;
  assessmentType: string;
  claimAreas: string | null;
  assessmentLocation: AssessmentLocation;
  preferredLocationVO: {
    latitude: number | null;
    location_name: string | null;
    longitude: number | null;
  };
  odometerValue: string;
  odometerUnit: string;
  fieldTwo: string | null;
  companyLogoUrl: string;
  companyName: string;
  companyId: string;
  companyWebSite: string | null;
  companyContactNumber: string;
  noOfAssessmentFiles: string | null;
  dynamicLinkId: string | null;
  externalAIReportUrl: string;
  htmlContent: string | null;
  templateName: string;
  pdfReportLocalFilePath: string;
  mergedPdfReportLocalFilePath: string | null;
  htmlReportLocalFilePath: string;
  qrCode: string;
  awsKey: string;
  finalPdfReportUrl: string;
  sectionWiseDamageAreasList: SectionWiseDamageAreaList[];
  assessmentFilesSectionWiseList: AssessmentFilesSectionWiseList[];
  answers: Answer[];
  transunionOriginalReportUrl: string | null;
  gtReportUrl: string;
  yearOfVehicle: string | null;
  coverageDetails: CoverageDetails;
  gtEstimateDetails: GTEstimateDetails;
  transunionDetails: TransUnionDetails | null;
  saintAPIDetails: SaintAPIDetails | null;
  vehicleScoreDetails: {
    interiorVehicleScore: string | null;
    interiorVehicleCondition: string | null;
    exteriorVehicleScore: string | null;
    exteriorVehicleCondition: string | null;
    interiorStatusCss: string | null;
    exteriorStatusCss: string | null;
    scoreConditionMeterImageURL: string | null;
  };
  globalRepairParts: string[];
  globalReplaceParts: string[];
  areaWisePinCounts: PinDamagesData;
  accessToken: string;
}

// Interface for area wise pin damages count
export interface PinDamagesData {
  Rear: {
    chargeableCount: number;
    nonChargeableCount: number;
  };
  Front: {
    chargeableCount: number;
    nonChargeableCount: number;
  };
  Driver: {
    chargeableCount: number;
    nonChargeableCount: number;
  };
  Passenger: {
    chargeableCount: number;
    nonChargeableCount: number;
  };
}

// Interface for location details
export interface AssessmentLocation {
  latitude: number;
  location_name: string;
  longitude: number;
}

// Enum for severity levels of damages
export enum SeverityLabel {
  VERY_SMALL = "Very Small",
  SMALL = "Small",
  MEDIUM = "Medium",
  SEVERE = "Severe",
  CRITICAL = "Critical",
  NO_DAMAGE = "No Damage"
}

// Define an enum for assessment types
export enum AssessmentType {
  CLAIM_SUBMISSION = 'CLAIM_SUBMISSION',
  PRE_INSPECTION = 'PRE_INSPECTION',
  QUOTES_TO_REPAIR = 'QUOTES_TO_REPAIR',
}

// Define an enum for transunion
export interface TransUnionDetails {
  vehicleTradeValueValid: boolean;
  vehicleRetailValueValid: boolean;
  estimatedVehicleTradeValueValid: boolean;
  estimatedVehicleRetailValueValid: boolean;
  newListValueValid: boolean;
  vehicle_trade_value: string;
  vehicle_retail_value: string;
  vehicle_estimated_trade_value: string;
  vehicle_estimated_retail_value: string;
  vehicle_new_list_value: string;
}

// Define an enum for saintAPIDetails
export interface SaintAPIDetails {
  servicePlanKMLeft: string;
  servicePlanYearsLeft: string;
  modelVerified: boolean;
  colorVerified: boolean;
  engineNumberVerified: boolean;
  regNumberVerified: boolean;
  vinNumberVerified: boolean;
  makeDescVerified: boolean;
  VinNumber: string;
  EngineNumber: string;
  RegNumber: string;
  Colour: string;
  Model: string;
  Make: Make;
  Roadworthy: string;
  RoadworthyDate: string;
  RegistrationDate: string;
  ServicePlanKM: string;
  InWarranty: string;
  ServicePlanYears: string;
}

// Define an enum for make
export interface Make {
  Code: string;
  Desc: string;
}