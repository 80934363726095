import { ReportData } from '../../../../models/AI_Report';  // Importing the data model for the report

// Component for displaying vehicle service details in the email report
const ERVehicleInfoSlideVehicleFeatureDetails = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer">
            <div className="top_part">
                {/* Display the icon for vehicle service details */}
                <img
                    alt=""
                    src="/images/email_report/car_info.svg"
                />
                <p className="m-0">Service Details</p> {/* Title for the section */}
            </div>

            <div className="body_part">
                <div className="data_part_container">
                    {/* Service Plan Kilometers */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Service Plan KM:</p> {/* Label for service plan kilometers */}
                        </div>
                        <div className="right_part">
                            {/* Display the service plan kilometers or 'NA' if not available */}
                            <p className="m-0">{reportData?.saintAPIDetails?.ServicePlanKM || 'NA'}</p>
                        </div>
                    </div>

                    {/* Service Plan Years */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Service Plan Year:</p> {/* Label for service plan years */}
                        </div>
                        <div className="right_part">
                            {/* Display the service plan years or 'NA' if not available */}
                            <p className="m-0">{reportData?.saintAPIDetails?.ServicePlanYears || 'NA'}</p>
                        </div>
                    </div>

                    {/* Service Plan Kilometers Left */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Service Plan KMs Left:</p> {/* Label for kilometers left on the service plan */}
                        </div>
                        <div className="right_part">
                            {/* Display the kilometers left or 'NA' if not available */}
                            <p className="m-0">{reportData?.saintAPIDetails?.servicePlanKMLeft || 'NA'}</p>
                        </div>
                    </div>

                    {/* Service Plan Years Left */}
                    <div className="data_part_outer">
                        <div className="left_part">
                            <p className="m-0">Service Plan Years Left:</p> {/* Label for years left on the service plan */}
                        </div>
                        <div className="right_part">
                            {/* Display the years left or 'NA' if not available */}
                            <p className="m-0">{reportData?.saintAPIDetails?.servicePlanYearsLeft || 'NA'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ERVehicleInfoSlideVehicleFeatureDetails;
