import React, { useState, createContext, useContext } from "react";
import { getCompanyOTP, setCompanySettings } from "../services/GenericService";
import api from "../api/api";
import { useLoader } from "./LoaderContext";
import { useSnackbar } from "./SnackbarContext";

export const CompanySettingContext = createContext<{
  companySettingState: any;
  getCompanyDetailsWithProvider: any;
} | null>(null);

export const useCompanySetting = () => {
  const context = useContext(CompanySettingContext);
  if (!context) {
    throw new Error("");
  }
  return context;
};

function CompanySettingProvider({ children }: { children: JSX.Element }) {
  const [companySettingState, setCompanySettingState] = useState<any>();
  const { showLoader, hideLoader } = useLoader();
  const { openSnackbar } = useSnackbar();
  const otp = getCompanyOTP();

  const getCompanyDetailsWithProvider = async (companyOTP: string = otp) => {
    showLoader();
    if (companyOTP) {
      try {
        const response = await api.getCompanyDetails(companyOTP);
        const data = response.data;
        setCompanySettingState(data);
        setCompanySettings(data);
      } catch (error) {
        console.log("error", error);
      } finally {
        hideLoader();
      }
    } else {
      openSnackbar(
        "Company OTP not available, select it first.",
        "error",
        4000
      );
    }
  };

  return (
    <CompanySettingContext.Provider
      value={{ companySettingState, getCompanyDetailsWithProvider }}
    >
      {children}
    </CompanySettingContext.Provider>
  );
}

export default CompanySettingProvider;
