import { ReportData } from '../../../../models/AI_Report';  // Importing the data model for the report

// Component for displaying vehicle score and condition in the email report
const ERVehicleInfoSlideScore = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer">
            <div className="top_part">
                {/* Display the icon for vehicle score and condition */}
                <img
                    src="/images/email_report/crat_metre_icon.svg"
                    alt=""
                />
                <p className="m-0">Vehicle Score & Condition</p> {/* Title for the section */}
            </div>

            <div className="body_part">
                <div className="graph_img_and_data_outer">
                    {/* Section for displaying the vehicle condition meter image */}
                    <div className="img_part">
                        <img
                            src={reportData?.vehicleScoreDetails?.scoreConditionMeterImageURL || ""}  // Display the image or an empty string if unavailable
                            alt="Vehicle Score Meter"
                        />
                    </div>

                    {/* Section for displaying the exterior and interior score data */}
                    <div className="data_part">
                        {/* Divider for Exterior Score and Status */}
                        <div className="data_divider_part">
                            <div className="data_text_outer">
                                <div className="left_text">
                                    <p className="m-0">Exterior Score :</p> {/* Label for exterior score */}
                                </div>
                                <div className="right_text">
                                    {/* Display the exterior vehicle score or 'NA' if unavailable */}
                                    <p className="m-0">{reportData?.vehicleScoreDetails?.exteriorVehicleScore || 'NA'}</p>
                                </div>
                            </div>
                            <div className="data_text_outer">
                                <div className="left_text">
                                    <p className="m-0">Exterior Status :</p> {/* Label for exterior status */}
                                </div>
                                <div className="right_text">
                                    {/* Display the exterior vehicle condition or 'NA' if unavailable */}
                                    <p className={reportData?.vehicleScoreDetails?.exteriorStatusCss || ""}>
                                        {reportData?.vehicleScoreDetails?.exteriorVehicleCondition || 'NA'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Divider for Interior Score and Status */}
                        <div className="data_divider_part">
                            <div className="data_text_outer">
                                <div className="left_text">
                                    <p className="m-0">Interior Score :</p> {/* Label for interior score */}
                                </div>
                                <div className="right_text">
                                    {/* Display the interior vehicle score or 'NA' if unavailable */}
                                    <p className="m-0">{reportData?.vehicleScoreDetails?.interiorVehicleScore || 'NA'}</p>
                                </div>
                            </div>
                            <div className="data_text_outer">
                                <div className="left_text">
                                    <p className="m-0">Interior Status :</p> {/* Label for interior status */}
                                </div>
                                <div className="right_text">
                                    {/* Display the interior vehicle condition or 'NA' if unavailable */}
                                    <p className={reportData?.vehicleScoreDetails?.interiorStatusCss || ""}>
                                        {reportData?.vehicleScoreDetails?.interiorVehicleCondition || 'NA'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ERVehicleInfoSlideScore;
