import { Select, MenuItem, ToggleButtonGroup, ToggleButton, IconButton, Tooltip } from '@mui/material'; // Importing Material UI components
import { useState, useEffect, useRef } from 'react'; // Importing React hooks
import ERVehicleInfoSlide from './ERVehicleInfoSlide'; // Importing various slide components
import ERVehicleInfoSlideAssessmentDetails from './ERVehicleInfoSlideAssessmentDetails';
import ERVehicleInfoSlideCoverage from './ERVehicleInfoSlideCoverage';
import ERVehicleInfoSlideGtTu from './ERVehicleInfoSlideGtTu';
import ERVehicleInfoSlideImageLocation from './ERVehicleInfoSlideImageLocation';
import ERVehicleInfoSlidePinDamages from './ERVehicleInfoSlidePinDamages';
import ERVehicleInfoSlideScore from './ERVehicleInfoSlideScore';
import ERVehicleInfoSlideVehicleFeatureDetails from './ERVehicleInfoSlideVehicleFeatureDetails';
import ERVehicleInfoSlideVehicleStatus from './ERVehicleInfoSlideVehicleStatus';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // Icon for previous button
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // Icon for next button
import { ReportData } from '../../../../models/AI_Report'; // Importing the data model for the report
import '../../SCSS/email_report_upper_cards.scss'; // Importing styles for Email Report QnA

// Main component for the vehicle info slider
const EmailReportVehileInfoSlider = ({ reportData }: { reportData: ReportData }) => {

    // State for holding the list of slider items and their respective components
    const [vehicleInfoSliderList, setVehicleInfoSliderList] = useState([
        { name: "vehicle_info", label: "Vehicle Information", component: <ERVehicleInfoSlide reportData={reportData} /> },
        { name: "assessment_details", label: "Assessment Details", component: <ERVehicleInfoSlideAssessmentDetails reportData={reportData} /> },
        { name: "image_location", label: "Inspection Location", component: <ERVehicleInfoSlideImageLocation reportData={reportData} /> },
        { name: "coverage", label: "Image Coverage", component: <ERVehicleInfoSlideCoverage reportData={reportData} /> }
    ]);

    // Effect to conditionally add additional slider components based on the report data
    useEffect(() => {
        if (reportData) {
            setVehicleInfoSliderList(prevList => {
                let updatedList = [...prevList];

                // Helper function to check if a section already exists in the list
                const sectionExists = (name: string) => updatedList.some(item => item.name === name);

                // Add vehicle status and service details if saintAPIDetails exist
                if (reportData?.saintAPIDetails) {
                    if (!sectionExists("vehicle_status")) {
                        updatedList.push({
                            name: "vehicle_status",
                            label: "Vehicle Status",
                            component: <ERVehicleInfoSlideVehicleStatus reportData={reportData} />
                        });
                    }

                    if (!sectionExists("service_details")) {
                        updatedList.push({
                            name: "service_details",
                            label: "Service Details",
                            component: <ERVehicleInfoSlideVehicleFeatureDetails reportData={reportData} />
                        });
                    }
                }

                // Add score section if vehicleScoreDetails exist
                if (reportData?.vehicleScoreDetails?.scoreConditionMeterImageURL) {
                    if (!sectionExists("score")) {
                        updatedList.push({
                            name: "score",
                            label: "Vehicle Score & Condition",
                            component: <ERVehicleInfoSlideScore reportData={reportData} />
                        });
                    }
                }

                // Add pin damages section if areaWisePinCounts exist
                if (reportData?.areaWisePinCounts && Object.keys(reportData.areaWisePinCounts).length > 0) {
                    if (!sectionExists("pin_damages")) {
                        updatedList.push({
                            name: "pin_damages",
                            label: "Pin Damages",
                            component: <ERVehicleInfoSlidePinDamages pinDamagesData={reportData?.areaWisePinCounts} />
                        });
                    }
                }

                // Add GT/TU section if transunionDetails or gtEstimateDetails exist
                if (reportData?.transunionDetails || reportData?.gtEstimateDetails) {
                    if (!sectionExists("gt_tu")) {
                        updatedList.push({
                            name: "gt_tu",
                            label: "GT/TU Details",
                            component: <ERVehicleInfoSlideGtTu reportData={reportData} />
                        });
                    }
                }

                return updatedList; // Return the updated list with new sections
            });
        }
    }, [reportData]); // Effect depends on the reportData prop

    // State for managing the current slide index and fade-in effect
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeIn, setFadeIn] = useState(false); // State to handle fade-in animation

    // Variables for determining the previous and next slides
    const selectedData = vehicleInfoSliderList[currentIndex];
    const prevIndex = (currentIndex === 0) ? vehicleInfoSliderList.length - 1 : currentIndex - 1;
    const nextIndex = (currentIndex === vehicleInfoSliderList.length - 1) ? 0 : currentIndex + 1;
    const buttonGroupRef: React.RefObject<HTMLButtonElement> = useRef(null); // Ref for button elements

    // Function to scroll to the selected button
    const scrollToButton = (index: number) => {
        const buttonGroup = buttonGroupRef.current;
        const selectedButton = buttonGroup?.querySelectorAll('button')[index];

        if (selectedButton && buttonGroup) {
            // Scroll to the selected button smoothly
            const buttonOffsetLeft = selectedButton.offsetLeft;
            const buttonWidth = selectedButton.offsetWidth;

            // Scroll the button group to make the selected button visible in the middle
            buttonGroup.scrollTo({
                left: buttonOffsetLeft - (buttonGroup.offsetWidth / 2 - buttonWidth / 2),
                behavior: 'smooth', // Smooth scroll effect
            });
        }
    };

    // Scroll to the selected button whenever the selectedData changes
    useEffect(() => {
        const index = vehicleInfoSliderList.findIndex((slide) => slide.name === selectedData.name);
        if (index !== -1) {
            setCurrentIndex(index); // Update current index
            scrollToButton(index); // Scroll to the selected button
        }
    }, [selectedData, vehicleInfoSliderList]);

    // Function to handle the change of slide when a user selects a different section
    const handleChange = (name: string) => {
        const index = vehicleInfoSliderList.findIndex((item) => item.name === name);
        if (index !== -1) {
            setFadeIn(false);  // Start fade-out animation
            setTimeout(() => {
                setCurrentIndex(index); // Update the current slide index
                setFadeIn(true);  // Start fade-in after content change
            }, 300);  // Timing should match the fade-out transition
        }
    };

    // Function to handle moving to the next slide
    const handleNext = () => {
        setFadeIn(false);  // Start fade-out animation
        setTimeout(() => {
            setCurrentIndex(nextIndex);
            setFadeIn(true);  // Start fade-in after content change
        }, 300);  // Timing should match the fade-out transition
    };

    // Function to handle moving to the previous slide
    const handlePrev = () => {
        setFadeIn(false);  // Start fade-out animation
        setTimeout(() => {
            setCurrentIndex(prevIndex);
            setFadeIn(true);  // Start fade-in after content change
        }, 300);  // Timing should match the fade-out transition
    };

    // Effect to handle the fade-in effect whenever the currentIndex changes
    useEffect(() => {
        setFadeIn(true); // Enable fade-in on component mount
    }, [currentIndex]);

    // Variables to handle touch events for swiping through slides
    const touchStartX = useRef(0); // Track touch start position
    const touchEndX = useRef(0); // Track touch end position

    const handleTouchStart = (event: any) => {
        touchStartX.current = event.touches ? event.touches[0].clientX : event.clientX; // Store initial touch/mouse position
    };

    const handleTouchMove = (event: any) => {
        touchEndX.current = event.touches ? event.touches[0].clientX : event.clientX; // Track movement during swipe
    };

    // Function to handle swipe action to the next or previous slide
    const handleTouchEnd = () => {
        if (touchStartX.current - touchEndX.current > 100) {
            handleNext(); // Swipe left to go to the next slide
        }

        if (touchStartX.current - touchEndX.current < -100) {
            handlePrev(); // Swipe right to go to the previous slide
        }
    };

    return (
        <>
            {/* Dropdown for selecting a vehicle info section */}
            <Select
                value={selectedData.name}
                onChange={(event) => handleChange(event.target.value)}  // Trigger handleChange on selection
                displayEmpty className='side_selection_dropdown'
            >
                {vehicleInfoSliderList.map((slide) => (
                    <MenuItem key={slide.name} value={slide.name}>
                        {slide.label} {/* Label for each slide */}
                    </MenuItem>
                ))}
            </Select>

            {/* Toggle buttons for vehicle info sections */}
            <div className="side_selection_button_toggle_wrapper">
                <ToggleButtonGroup
                    ref={buttonGroupRef}
                    value={selectedData.name}
                    exclusive
                    onChange={(event, newValue) => {
                        if (newValue !== null) {
                            handleChange(newValue); // Trigger handleChange on button toggle
                        }
                    }}
                    aria-label="Vehicle sides"
                    className='side_selection_button_toggle'
                >
                    {vehicleInfoSliderList.map((slide) => (
                        <ToggleButton key={slide.name} value={slide.name}>
                            {slide.label} {/* Label for each slide */}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>

            {/* Slider container to display the current, previous, and next slides */}
            <div className="slider_view_container_wrapper" onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleTouchStart}
                onMouseMove={handleTouchMove}
                onMouseUp={handleTouchEnd}>
                <IconButton onClick={handlePrev} aria-label="previous" className='icon_outer prev_icon_outer'>
                    <ChevronLeftIcon className='prev_next_icon' /> {/* Left arrow for previous */}
                </IconButton>
                <IconButton onClick={handleNext} aria-label="next" className='icon_outer next_icon_outer'>
                    <ChevronRightIcon className='prev_next_icon' /> {/* Right arrow for next */}
                </IconButton>
                <div className="slider_view_container">
                    <div className="slider_conatiner_outer previous_slide">
                        {vehicleInfoSliderList[prevIndex].component} {/* Previous slide content */}
                    </div>
                    <div className={`slider_conatiner_outer current_slide ${fadeIn ? 'current_slide_fade_in' : ''}`}>
                        {selectedData.component} {/* Current slide content */}
                    </div>
                    <div className="slider_conatiner_outer next_slide">
                        {vehicleInfoSliderList[nextIndex].component} {/* Next slide content */}
                    </div>
                </div>
            </div>

            {/* Dots or buttons for quick navigation between slides */}
            <div className="rounded_clickable_button_container">
                {vehicleInfoSliderList.map((slide) => (
                    <Tooltip key={slide.name} title={slide.label} arrow>
                        <IconButton
                            type="button"
                            onClick={() => handleChange(slide.name)}
                            className={slide.name === selectedData.name ? "selectedSideRoundedElement" : ''}
                        >
                        </IconButton>
                    </Tooltip>
                ))}
            </div>
        </>
    );
};

export default EmailReportVehileInfoSlider;
