import React, { Suspense } from 'react';
import { Routes, Route, Navigate, RouteProps } from 'react-router-dom';  // Import routing components
import { isLoggedIn } from '../pages/Login/Services/AuthService';  // Import authentication check function
import { LinearProgress } from '@mui/material';  // Import loading indicator
import Email_Report from '../pages/Email_Report/Email_Report';  // Import the Email Report component

// Lazy load the Login and Container components to improve performance
const Login = React.lazy(() => import('../pages/Login/Login'));
const Container = React.lazy(() => import('../pages/Container/Container'));

// AuthGuard component to protect routes based on login status
const AuthGuard: React.FC<RouteProps> = ({ children }) => {
  // Check if user is logged in, redirect to login page if not
  return isLoggedIn() ? <>{children}</> : <Navigate to="/login" replace />;
};

// Main navigation component for handling routing in the app
const Navigations: React.FC = () => {
  
  // Inline style for the loading indicator during lazy loading
  const linearProgressContainerStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 9999
  };

  return (
    // Suspense component used to show a loading indicator while lazy-loaded components are being fetched
    <Suspense fallback={<div style={linearProgressContainerStyle}><LinearProgress /></div>}>
      <Routes>
        {/* Route for the login page */}
        <Route path="/login" element={<Login />} />

        {/* Protected route for product-related pages, wrapped with AuthGuard */}
        <Route
          path="/product/*"
          element={
            <AuthGuard>
              {/* Suspense fallback while the container is being loaded */}
              <Suspense fallback={<div style={linearProgressContainerStyle}><LinearProgress /></div>}>
                <Container />
              </Suspense>
            </AuthGuard>
          }
        />

        {/* Route for the email report, parameterized by the assessment_id */}
        <Route path="email-report/:assessment_id" element={<Email_Report />} />

        {/* Catch-all route that redirects to the home page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};

export default Navigations;
