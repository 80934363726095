import React from 'react';
import '../SCSS/email_report_headers.scss'; // Importing styles for Email Report Headers

// Defining props for EmailReportHeader2 component
interface EmailReportHeader2Props {
  qrCode: string; // QR code image URL
  reportCreatedDate: string; // Date when the report was created
  reportCreatedTime: string; // Time when the report was created
  companyLogoUrl: string; // URL for the company's logo
}

// Functional component EmailReportHeader2
const EmailReportHeader2: React.FC<EmailReportHeader2Props> = ({ qrCode, reportCreatedDate, reportCreatedTime, companyLogoUrl }) => {
  return (
    <header>
      {/* Left section with QR code and date/time details */}
      <div className="left_part">
        {/* Section to display the QR code */}
        <div className="scanner_part">
          <img src={qrCode} alt="QR Code" /> {/* Display the QR code */}
        </div>

        {/* Section to display the report date and time */}
        <div className="date_time_part">
          {/* Report title */}
          <p className="header_text m-0">Vehicle Inspection Assessment</p>

          {/* Container for displaying the report creation date */}
          <div className="date_or_time_container">
            <img src="/images/email_report/calender_icon.svg" alt="Calendar Icon" /> {/* Calendar icon */}
            <p className="m-0">{reportCreatedDate}</p> {/* Report creation date */}
          </div>

          {/* Container for displaying the report creation time */}
          <div className="date_or_time_container">
            <img src="/images/email_report/clock_icon.svg" alt="Clock Icon" /> {/* Clock icon */}
            <p className="m-0">{reportCreatedTime}</p> {/* Report creation time */}
          </div>
        </div>
      </div>

      {/* Right section with the company logo */}
      <div className="right_part">
        <div className="client_logo">
          <img src={companyLogoUrl} alt="Client Logo" /> {/* Display the company's logo */}
        </div>
      </div>
    </header>
  );
};

export default EmailReportHeader2;
