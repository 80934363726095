import React from "react";
import "./App.scss";  // Import the global styles for the app
import Navigations from "./navigations/Navigations";  // Import the navigation component for route handling
import { SnackbarProvider } from "./context/SnackbarContext";  // Import Snackbar context provider for managing notifications
import { LoaderProvider } from "./context/LoaderContext";  // Import Loader context provider for managing loaders
import CompanySettingProvider from "./context/CompanySettingContext";  // Import Company settings provider for app-wide company-related settings
import { AuthProvider } from "./context/AuthContext";  // Import Authentication context provider for managing auth state
import { BrowserRouter } from "react-router-dom";  // Import BrowserRouter for handling client-side routing

// Main application component wrapped in multiple context providers
function App() {
  return (
    // BrowserRouter enables routing within the application
    <BrowserRouter>
      {/* SnackbarProvider provides snackbar (notification) functionality across the app */}
      <SnackbarProvider>
        {/* LoaderProvider provides a loading spinner for asynchronous tasks */}
        <LoaderProvider>
          {/* CompanySettingProvider manages company-related configurations globally */}
          <CompanySettingProvider>
            <div className="appContainer">
              {/* Navigations handles all the route definitions and navigation */}
              <Navigations />
            </div>
          </CompanySettingProvider>
        </LoaderProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

// AppWithAuthProvider wraps the App component in an authentication provider
const AppWithAuthProvider: React.FC = () => {
  return (
    // AuthProvider manages authentication state across the app
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AppWithAuthProvider;
