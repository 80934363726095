import React, { ChangeEvent, ReactElement } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { isDark } from "../services/GenericService";
import styles from "../pages/Container/Container.module.scss";
import IconButton from "@mui/material/IconButton";

interface CustomTextFieldProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  id: string;
  type: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  fullWidth?: boolean;
  Placeholder?: string;
  extraRootClasses?: string;
  icon?: ReactElement;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  type,
  value,
  onChange,
  label,
  required = false,
  fullWidth = false,
  placeholder,
  extraRootClasses = "",
  icon,
  ...props
}) => {
  return (
    <>
      <label htmlFor={id} className="block mb-1">
        {label}
      </label>
      <TextField
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        fullWidth={fullWidth}
        placeholder={placeholder}
        className={`${
          isDark()
            ? styles.containerWithDarkTheme
            : styles.containerWithLightTheme
        } rounded-lg ${extraRootClasses}`}
        InputProps={{
          endAdornment: icon ? (
            <IconButton size="small">{icon}</IconButton>
          ) : null,
        }}
        sx={{ "& .MuiInputBase-root": { borderRadius: "0.6rem" } }}
        {...props}
      />
    </>
  );
};

export default CustomTextField;
