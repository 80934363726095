import { IFeatureConfigurationButtonProps } from "../constants/dataTypes";

export enum storageKey {
  user_data = "userdata",
  theme = "theme",
  company_settings = "company_settings",
}

export enum userRole {
  product = "product",
  company = "company",
}

export enum ThemeType {
  light = "light",
  dark = "dark",
}

export enum LABELS {
  DashboardConfigurations = "Dashboard Configurations",
  AccessSuperAdminDashboard = "Access Super Admin Dashboard",
  EnableVehicleScoring = "Enable Vehicle Scoring",
  EditCarModelDetails = "Edit Car model details",
  GTTrigerring = "GT Trigerring",
  MobileAppConfigurations = "Mobile App Configurations",
  WhatsAppConfigurations = "WhatsApp Configurations",
  FeatureConfiguration = "Feature Cofigurations",
}

export const FeatureConfigurationsButton: IFeatureConfigurationButtonProps[] = [
  {
    label: "Pre-inspection",
    key: "PRE_INSPECTION",
  },
  {
    label: "Claims",
    key: "CLAIM_SUBMISSION",
  },
  // {
  //   label: "Both",
  //   key: "BOTH",
  // },
];

export enum FEATURE_CONFIGURATION_BUTTON_KEY {
  PRE_INSPECTION = "PRE_INSPECTION",
  CLAIM_SUBMISSION = "CLAIM_SUBMISSION",
  BOTH = "BOTH",
}

export enum GTTRIGERRING_BUTTON_KEY {
  MANUAL = "ON_CLIENT_REQUEST",
  AUTOMATIC = "ON_LABELLER_SUBMIT",
}

export const GTtrigerringButton: IFeatureConfigurationButtonProps[] = [
  {
    label: "Manual",
    key: GTTRIGERRING_BUTTON_KEY.MANUAL,
  },
  {
    label: "Automatic",
    key: GTTRIGERRING_BUTTON_KEY.AUTOMATIC,
  },
];

export enum MENUCONFIG_ID {
  WHATSAPP_MENU_ITEM = "whatsapp-menu-item",
  JOURNEY_SUBMENU_ITEM = "journey-submenu-item",
  QUESTIONAIRE_SUBMENU_ITEM = "questionaire-submenu-item",
  FEATURECONFIGURATION_SUBMENU_ITEM = "feature-submenu-item",
  MASKING_IMAGES = "skip_numberplate_masking",
  OCR_READING = "reading_from_ocr",
  //HYDRANET = "hydranet",
  SWITCH_HYDRA_NET_WHATSAPP = "hydranet",
  MOBILEAPP_MENU_ITEM = "mobileapp-menu-item",
  AUTOMATIC_GT_TRIGGERING = "automatic-gt-triggering",
  ASSESSMENT_LABELLERS_TEAM_MOBILE = "assessment-labellers-team-mobile",
  SWITCH_HYDRA_NET_MOBILE = "hydranet",
  DASHBOARD_MENU_ITEM = "dashboard-menu-item",
  OVERALL_DASHBOARD = "Overall Dashboard",
  JOURNEY_TYPE_SPECIFIC = "Journey Type specific",
  ACCESS_SUPER_ADMIN_DASHBOARD = "access-super-admin-dashboard",
  ENABLE_VEHICLE_SCORING = "vehicle_scoring",
  EXTERNAL_SCORING = "vehicle_scoring_exterior",
  INTERNAL_SCORING = "vehicle_scoring_interior",
  EDIT_FIELD_SENDING_DYNAMIC_LINK = "edit-field-sending-dynamic-link",
  MAILING_FEATURE = "mailing-feature",
  EDIT_CAR_MODEL_DETAILS = "edit-car-model-details",
  MAKE = "make",
  MODEL = "model",
  LICENCE_NUMBER = "licence_no",
  REGISTRATION_NUMBER = "registration_no",
  VIN_NUMBER = "vin_no",
  ENGINE_NUMBER = "engine_no",
  COLOR = "color",
  GT_TRIGGERING = "gt_estimate",
  GT_FUSION_AUTOMATION = "gt_fusion_purchased",
  GT_LINK_AUTOMATION = "gt_links_purchased",
  ASSESSMENT_GOING_LABELLERS_DASHBOARD = "assessment-going-labellers-team-dashboard",
  SCORING_MENU_ITEM = "scoring-menu-item",
}
