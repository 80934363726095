import '../SCSS/email_report_headers.scss'; // Importing styles for Email Report Headers
import CustomButton from '../../../components/CustomButton'; // Importing CustomButton component
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material'; // Material UI components
import { useState } from 'react'; // React hook for managing state
import CloseIcon from '@mui/icons-material/Close'; // Icon for closing dialog
import CopyIcon from '@mui/icons-material/CopyAllOutlined'; // Icon for copying text

// Renaming ChildComponentProps to EmailReportHeaderProps for better clarity
interface EmailReportHeaderProps {
    qrCode: string | undefined; // QR code URL (or undefined if not available)
    externalAIReportUrl: string | undefined; // External report URL (or undefined if not available)
    onDownloadReport: () => void; // Function prop passed from the parent to handle report download
}

// Functional component EmailReportHeader1
const EmailReportHeader1: React.FC<EmailReportHeaderProps> = ({ qrCode, externalAIReportUrl, onDownloadReport }) => {

    // Handler to call parent's function when the "Download PDF Report" button is clicked
    const handleDownLoadReport = () => {
        onDownloadReport();
    };

    // State to manage whether the QR dialog is open or closed
    const [open, setOpen] = useState(false);

    // Function to open the QR dialog
    const openQRdialog = () => {
        setOpen(true);
    };

    // Function to close the QR dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Text that will be copied when the user clicks the copy button (either the external report URL or an empty string)
    const textToCopy = externalAIReportUrl || '';

    // Function to copy the report link to the clipboard
    const copyReportLink = () => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // Handle success (if needed)
            })
            .catch(err => {
                console.error('Failed to copy text: ', err); // Log error if copying fails
            });
    };

    return (
        <header>
            {/* Left section containing logo and QR code */}
            <div className="left_part">
                {/* CarScan AI logo */}
                <img src="/images/email_report/carscan_transparent.svg" alt="CarScan AI Logo" className="logo_img" />
                {/* QR code section */}
                <div className="qr_part" onClick={openQRdialog}>
                    <img src="/images/email_report/QR_sample_logo.svg" alt="QR Code" />
                    <p className="m-0">View QR code</p>
                </div>
            </div>

            {/* Right section containing the "Download PDF Report" button */}
            <div className="right_part">
                <CustomButton
                    type="button"
                    variant="outlined"
                    color="inherit"
                    onClick={handleDownLoadReport}
                    startIcon={<img src="/images/email_report/download_icon.svg" />} // Icon next to the button text
                >
                    Download PDF Report
                </CustomButton>
            </div>

            {/* Dialog component to display enlarged QR code */}
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth className='qr_details_dialog_outer'>
                <DialogTitle className='qr_details_dialog_header'>
                    <p className="m-0">QR code</p>
                    {/* Icon to close the dialog */}
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon className='close_icon' />
                    </IconButton>
                </DialogTitle>

                {/* Dialog content */}
                <DialogContent className="dialog_content_part">
                    {/* Display the QR code (or a sample QR code if not available) */}
                    <img src={qrCode || '/images/email_report/QR_sample_logo.svg'} alt="QR Code" />
                    {/* Section to display the link and copy icon */}
                    <div className="copy_part">
                        <div className="left_part">
                            <p className="m-0">{textToCopy}</p> {/* Display the URL to be copied */}
                        </div>
                        <div className="right_part">
                            {/* Button to copy the report link */}
                            <IconButton aria-label="copy" className='copy_icon_outer' onClick={copyReportLink}>
                                <CopyIcon className='copy_icon' />
                            </IconButton>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </header>
    );
};

export default EmailReportHeader1;
