// import axios from 'axios';
// import { UserData } from '../constants/dataTypes';
// import { LocalStorageService } from '../services/LocalStorage';
// import { storageKey } from '../constants/constants';

// const instance = axios.create();

// instance.interceptors.request.use(
//     (config) => {
//         const user_data:UserData| null = LocalStorageService.getItem(storageKey.user_data);
//         if (user_data?.access_token) {
//             config.headers.Authorization = `Bearer ${user_data?.access_token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// export default instance;

// import axios from 'axios';

// const instance = axios.create();
// const refreshAccessToken = async () => {
//     const refreshToken = localStorage.getItem('refresh_token');
//     try {
//         const response = await axios.post('/refresh', { refresh_token: refreshToken });
//         const { access_token } = response.data;
//         localStorage.setItem('access_token', access_token);
//         return access_token;
//     } catch (error) {
//         logout();
//         throw new Error('Token refresh failed');
//     }
// };

// const handleInterceptorError = async (error:any) => {
//     if (error.response && error.response.status === 406) {
//         try {
//             const newAccessToken = await refreshAccessToken();
//             error.config.headers.Authorization = `Bearer ${newAccessToken}`;
//             return axios.request(error.config);
//         } catch (refreshError) {
//             logout();
//             throw refreshError;
//         }
//     }
//     throw error;
// };

// // Add request interceptor
// instance.interceptors.request.use(
//     (config) => {
//         // Add authorization header with access token
//         const accessToken = localStorage.getItem('access_token');
//         if (accessToken) {
//             config.headers.Authorization = `Bearer ${accessToken}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// // Add response interceptor
// instance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         return handleInterceptorError(error);
//     }
// );

// const logout = () => {
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('refresh_token');
//     window.location.href = '/'; // Redirect to login page
// };

// export default instance;

import axios from "axios";
import { UserData } from "../constants/dataTypes";
import { LocalStorageService } from "../services/LocalStorage";
import { storageKey } from "../constants/constants";
import { NavigateFunction, useNavigate } from "react-router-dom";
import api from "../api/api";

let isRefreshingToken = false; // Flag to indicate refresh token process
let failedQueue: any[] = []; // Queue to hold failed requests during the refresh process

const instance = axios.create();

// Function to refresh access token
const refreshAccessToken = async () => {
  const user_data: UserData | null = LocalStorageService.getItem(
    storageKey.user_data
  );

  const refreshToken = user_data?.refresh_token;
  const userId = user_data?.user_id;

  // Remove the expired access token
  if (user_data) {
    user_data.access_token = null;
    LocalStorageService.setItem(storageKey.user_data, user_data);
  }

  try {
    isRefreshingToken = true;
    const response = await api.refreshTokenAccess(refreshToken, userId);
    const { access_token } = response.data;
    if (user_data) {
      user_data.access_token = access_token;
    }
    LocalStorageService.setItem(storageKey.user_data, user_data);
    isRefreshingToken = false;
    processQueue(null, access_token);
    return access_token;
  } catch (error) {
    isRefreshingToken = false;
    processQueue(error, null);
    logout();
    console.log("checking refresh token api");
    throw new Error("Token refresh failed");
  }
};

// Process the queue of failed requests
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

//Function to handle interceptor errors
const handleInterceptorError = async (error: any) => {
  // Check if a token refresh is already in progress
  if (isRefreshingToken) {
    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject });
    })
      .then((token) => {
        error.config.headers.Authorization = `Bearer ${token}`;
        return axios.request(error.config);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  // Mark this request as a retry to prevent infinite loops
  error.config._retry = true;

  try {
    // No token refresh in progress, initiate token refresh
    const newAccessToken = await refreshAccessToken();
    // Update the failed request with the new access token
    error.config.headers.Authorization = `Bearer ${newAccessToken}`;
    console.log("refresh token worked");
    // Retry the original request
    return axios.request(error.config);
  } catch (refreshError) {
    // Handle errors during token refresh
    console.log("refresh token did not work");
    //logout();
    throw refreshError;
  }
};

instance.interceptors.request.use(
  (config) => {
    const user_data: UserData | null = LocalStorageService.getItem(
      storageKey.user_data
    );
    if (user_data?.access_token) {
      config.headers.Authorization = `Bearer ${user_data.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 406 status code
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // If status code is 406, perform logout
    const originalRequest = error.config;
    if (
      error.response &&
      (error.response.status === 406 || error.response.status === 500) &&
      !originalRequest._retry
    ) {
      console.log("error after access token expires");
      // const navigate = useNavigate();
      // logout(navigate);
      return handleInterceptorError(error);
      //logout();
    }
    return Promise.reject(error);
  }
);

const logout = () => {
  LocalStorageService.clear();
  window.location.href = "/login";
};

export default instance;
