import React from "react";
import { Button, ButtonProps } from "@mui/material";

interface CustomButtonProps extends ButtonProps {
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  children: React.ReactNode | string;
  startIcon?: React.ReactNode; // Add startIcon prop
}

const CustomButton: React.FC<CustomButtonProps> = ({
  type,
  variant,
  color,
  disabled,
  fullWidth,
  onClick,
  children,
  className,
  startIcon, // Destructure startIcon prop
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      className={className}
      onClick={onClick}
      startIcon={startIcon} // Pass startIcon prop to Button component
    >
      {children}
    </Button>
  );
};

export default CustomButton;
