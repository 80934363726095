import { IConfigurationProps } from "./dataTypes";
import { MENUCONFIG_ID } from "./constants";

export const Configurations: IConfigurationProps[] = [
  {
    id: MENUCONFIG_ID.WHATSAPP_MENU_ITEM,
    label: "WhatsApp Configurations",
    submenu: [
      {
        id: MENUCONFIG_ID.JOURNEY_SUBMENU_ITEM,
        label: "Journey Configurations ",
        to: "/product/whatsApp-configurations/journey-configurations",
      },
      {
        id: MENUCONFIG_ID.QUESTIONAIRE_SUBMENU_ITEM,
        label: "Questionaire Configurations",
        to: "/product/whatsApp-configurations/questionaire-cofiguration",
      },
      {
        id: MENUCONFIG_ID.FEATURECONFIGURATION_SUBMENU_ITEM,
        label: "Feature Configurations",
        to: "/product/whatsApp-configurations/feature-cofiguration",
        features: [
          // {
          //   id: MENUCONFIG_ID.MASKING_IMAGES,
          //   featureName: "Masking/ unmasking images",
          //   isPurchased: false,
          // },
          {
            id: MENUCONFIG_ID.OCR_READING,
            featureName: "OCR reading through number plate configuring",
            isPurchased: false,
          },
          {
            id: MENUCONFIG_ID.SWITCH_HYDRA_NET_WHATSAPP,
            featureName: "Enable Hydra-Net",
            isPurchased: false,
          },
        ],
      },
    ],
  },
  {
    id: MENUCONFIG_ID.MOBILEAPP_MENU_ITEM,
    label: "Mobile App Configurations",
    to: "/product/mobile-app-configurations",
    features: [
      // {
      //   id: MENUCONFIG_ID.AUTOMATIC_GT_TRIGGERING,
      //   featureName: "Automatic GT triggering",
      //   isPurchased: false,
      // },
      // {
      //   id: MENUCONFIG_ID.ASSESSMENT_LABELLERS_TEAM_MOBILE,
      //   featureName: "Assessments going to labellers team",
      //   isPurchased: false,
      // },
      {
        id: MENUCONFIG_ID.SWITCH_HYDRA_NET_MOBILE,
        featureName: "Enable Hydra-Net",
        isPurchased: false,
      },
    ],
  },
  {
    id: MENUCONFIG_ID.DASHBOARD_MENU_ITEM,
    label: "Dashboard Configurations",
    submenu: [
      {
        id: MENUCONFIG_ID.OVERALL_DASHBOARD,
        label: "Overall Dashboard",
        to: "/product/dashboard-configurations/OverallDashboard",
        features: [
          {
            id: MENUCONFIG_ID.ACCESS_SUPER_ADMIN_DASHBOARD,
            featureName: "Access Super Admin Dashboard",
            isPurchased: false,
          },
          {
            id: MENUCONFIG_ID.EDIT_FIELD_SENDING_DYNAMIC_LINK,
            featureName: "Edit Fields for sending Dynamic Link",
            isPurchased: false,
          },
          // {
          //   id: MENUCONFIG_ID.ASSESSMENT_GOING_LABELLERS_DASHBOARD,
          //   featureName: "Assessments going to labellers team",
          //   isPurchased: false,
          // },
          // {
          //   id: MENUCONFIG_ID.MASKING_IMAGES,
          //   featureName: "Masking/ unmasking images",
          //   isPurchased: false,
          // },
          // {
          //   id: MENUCONFIG_ID.OCR_READING,
          //   featureName: "OCR reading through number plate configuring",
          //   isPurchased: false,
          // },
        ],
      },
      {
        id: MENUCONFIG_ID.JOURNEY_TYPE_SPECIFIC,
        label: "Journey Type specific",
        to: "/product/dashboard-configurations/JourneyTypeSpecific",
        features: [
          {
            id: MENUCONFIG_ID.ENABLE_VEHICLE_SCORING,
            featureName: "Enable Vehicle Scoring",
            scoring: [
              {
                scoring_id: MENUCONFIG_ID.EXTERNAL_SCORING,
                scoringName: "External Scoring",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.INTERNAL_SCORING,
                scoringName: "Internal Scoring",
                isPurchased: false,
              },
            ],
          },
          {
            id: MENUCONFIG_ID.EDIT_CAR_MODEL_DETAILS,
            featureName: "Edit Car model details",
            scoring: [
              {
                scoring_id: MENUCONFIG_ID.MAKE,
                scoringName: "Make",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.MODEL,
                scoringName: "Model",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.LICENCE_NUMBER,
                scoringName: "Licence No.",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.REGISTRATION_NUMBER,
                scoringName: "Registration No.",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.VIN_NUMBER,
                scoringName: "VIN No.",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.ENGINE_NUMBER,
                scoringName: "Engine No.",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.COLOR,
                scoringName: "Color",
                isPurchased: false,
              },
            ],
          },
          {
            id: MENUCONFIG_ID.ASSESSMENT_GOING_LABELLERS_DASHBOARD,
            featureName: "Assessments going to labellers team",
            isPurchased: false,
          },
          {
            id: MENUCONFIG_ID.MASKING_IMAGES,
            featureName: "Masking/ unmasking images",
            isPurchased: false,
          },
          {
            id: MENUCONFIG_ID.OCR_READING,
            featureName: "OCR reading through number plate configuring",
            isPurchased: false,
          },
          {
            id: MENUCONFIG_ID.GT_TRIGGERING,
            featureName: "GT Trigerring",
            scoring: [
              {
                scoring_id: MENUCONFIG_ID.GT_FUSION_AUTOMATION,
                scoringName: "GT fusion automation",
                isPurchased: false,
              },
              {
                scoring_id: MENUCONFIG_ID.GT_LINK_AUTOMATION,
                scoringName: "GT link automation",
                isPurchased: false,
              },
            ],
          },
        ],
      },
    ],
  },
  // {
  //   id: MENUCONFIG_ID.DASHBOARD_MENU_ITEM,
  //   label: "Dashboard Configurations",
  //   to: "/product/dashboard-configurations",
  //   features: [
  //     {
  //       id: MENUCONFIG_ID.ACCESS_SUPER_ADMIN_DASHBOARD,
  //       featureName: "Access Super Admin Dashboard",
  //       isPurchased: false,
  //     },
  //     {
  //       id: MENUCONFIG_ID.ENABLE_VEHICLE_SCORING,
  //       featureName: "Enable Vehicle Scoring",
  //       scoring: [
  //         {
  //           scoring_id: MENUCONFIG_ID.EXTERNAL_SCORING,
  //           scoringName: "External Scoring",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.INTERNAL_SCORING,
  //           scoringName: "Internal Scoring",
  //           isPurchased: false,
  //         },
  //       ],
  //     },
  //     {
  //       id: MENUCONFIG_ID.EDIT_FIELD_SENDING_DYNAMIC_LINK,
  //       featureName: "Edit Fields for sending Dynamic Link",
  //       isPurchased: false,
  //     },
  //     // {
  //     //   id: MENUCONFIG_ID.MAILING_FEATURE,
  //     //   featureName: "Mailing features",
  //     //   isPurchased: false,
  //     // },
  //     {
  //       id: MENUCONFIG_ID.EDIT_CAR_MODEL_DETAILS,
  //       featureName: "Edit Car model details",
  //       scoring: [
  //         {
  //           scoring_id: MENUCONFIG_ID.MAKE,
  //           scoringName: "Make",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.MODEL,
  //           scoringName: "Model",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.LICENCE_NUMBER,
  //           scoringName: "Licence No.",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.REGISTRATION_NUMBER,
  //           scoringName: "Registration No.",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.VIN_NUMBER,
  //           scoringName: "VIN No.",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.ENGINE_NUMBER,
  //           scoringName: "Engine No.",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.COLOR,
  //           scoringName: "Color",
  //           isPurchased: false,
  //         },
  //       ],
  //     },
  //     {
  //       id: MENUCONFIG_ID.GT_TRIGGERING,
  //       featureName: "GT Trigerring",
  //       scoring: [
  //         {
  //           scoring_id: MENUCONFIG_ID.GT_FUSION_AUTOMATION,
  //           scoringName: "GT fusion automation",
  //           isPurchased: false,
  //         },
  //         {
  //           scoring_id: MENUCONFIG_ID.GT_LINK_AUTOMATION,
  //           scoringName: "GT link automation",
  //           isPurchased: false,
  //         },
  //       ],
  //     },
  //     {
  //       id: MENUCONFIG_ID.ASSESSMENT_GOING_LABELLERS_DASHBOARD,
  //       featureName: "Assessments going to labellers team",
  //       isPurchased: false,
  //     },
  //   ],
  // },
  // {
  //   id: MENUCONFIG_ID.SCORING_MENU_ITEM,
  //   label: "Scoring Configurations",
  //   to: "/product/scoring-configurations",
  // },
];

export const product_configs_hardCode = [
  {
    assessment_type: "PRE_INSPECTION",

    features_purchased: {
      is_hydranet_purchased: true,

      can_read_from_ocr: false,

      can_skip_numberplate_masking: true,

      vehicle_scoring_exterior: false,

      vehicle_scoring_interior: true,
    },
  },

  {
    assessment_type: "CLAIM_SUBMISSION",

    features_purchased: {
      is_hydranet_purchased: false,

      can_read_from_ocr: false,

      can_skip_numberplate_masking: false,

      gt_fusion_purchased: true,

      gt_links_purchased: false,
    },
  },
];

export const product_configs_new = [
  {
    assessment_type: "PRE_INSPECTION",

    features_purchased: [
      {
        name: "hydranet",

        is_enabled: true,

        feature_type: ["INTERIOR", "EXTERIOR"],

        platform: ["WHATSAPP", "MOBILE_APP"],
      },

      {
        name: "reading_from_ocr",

        is_enabled: true,

        platform: ["WHATSAPP", "MOBILE_APP"],
      },

      {
        name: "skip_numberplate_masking",

        is_enabled: true,

        platform: ["WHATSAPP", "MOBILE_APP"],
      },

      {
        name: "vehicle_scoring",

        is_enabled: true,

        feature_type: ["INTERIOR", "EXTERIOR"],
      },

      {
        name: "gt_estimate",

        is_enabled: true,

        feature_type: ["GT_LINKS"], //"GT_FUSION",

        event_type: "ON_LABELLER_SUBMIT", //"ON_LABELLER_SUBMIT"
      },
    ],
  },

  {
    assessment_type: "CLAIM_SUBMISSION",

    features_purchased: [
      {
        name: "hydranet",

        is_enabled: false,
      },

      {
        name: "reading_from_ocr",

        is_enabled: false,
      },

      {
        name: "skip_numberplate_masking",

        is_enabled: false,
      },

      {
        name: "vehicle_scoring",

        is_enabled: false,
      },

      {
        name: "gt_estimate",

        is_enabled: false,
      },
    ],
  },
];
