import './SCSS/email_report_container.scss'; // Importing styles for Email Report Container
import EmailReportHeader1 from './Components/EmailReportHeader1';
import EmailReportHeader2 from './Components/EmailReportHeader2';
import EmailReportFooter from './Components/EmailReportFooter';
import EmailReportQnA from './Components/EmailReportQnA';
import EmailReportCarSideWiseView from './Components/EmailReportCarSideWiseView';
import EmailReportImageDetails from './Components/EmailReportImageDetails';
import { useState, useEffect } from 'react';
import EmailReportVehileInfoSlider from './Components/SliderComponents/EmailReportVehileInfoSlider';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import CustomTextField from '../../components/CustomTextField';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom'; // No need to import ErrorResponse
import { endpoint } from '../../api/constants';
import { AssessmentFile, ReportData, RouteParams } from '../../models/AI_Report';
import { useLoader } from '../../context/LoaderContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { getSnackbarMessage } from '../../services/GenericService';

// Main component
const Email_Report = () => {
    // States for various data, errors, loading status, etc.
    const [isScrolled, setIsScrolled] = useState(false); // Tracks scroll state
    const [tokenFormCommonError, setJWTFormCommonError] = useState("");
    const [isMinorDamage, setIsMinorDamage] = useState(false); // Tracks if minor damage view is required
    const [isPinnedDamage, setIsPinnedDamage] = useState(true); // Tracks if pinned damage view is required
    const [isTokenExpired, setIsTokenExpired] = useState(false); // Tracks if JWT token is expired
    const [formData, setFormData] = useState({
        contact_no: '',
        vin_no: ''
    });
    const [errors, setErrors] = useState({
        contact_no: '',
        vin_no: ''
    });

    const { assessment_id } = useParams<RouteParams>(); // Extract assessment_id from route params
    const location = useLocation(); // Gets the query string from the URL
    const [reportData, setReportData] = useState<ReportData | null>(null); // Holds report data
    const [isLoading, setIsLoading] = useState<boolean>(true); // Tracks loading state
    const [jwt, setJWT] = useState<string | null>(null); // JWT token state
    const [isError, setIsError] = useState<string | null>(null); // Error state
    const { showLoader, hideLoader } = useLoader(); // Loader context for managing loading UI
    const [assessmentFiles, setassessmentFiles] = useState<AssessmentFile[] | []>([]); // Assessment files list

    // Effect to fetch the report details when the component mounts or jwt changes
    useEffect(() => {
        setReportData(null);
        processReportData(null); // Clear report data initially
        // Get the token from the query string
        const queryParams = new URLSearchParams(location.search);
        setJWT(queryParams.get('jwt')); // Extract JWT token from URL
        setIsMinorDamage(queryParams.get('showMinor') === 'true' ? true : false); // Check for 'showMinor' flag in query params
        if (assessment_id && jwt) {
            getAIreportDetails(); // Fetch the AI report details if both assessment_id and jwt are available
        }
    }, [jwt]);

    // Function to fetch AI report details using axios
    const getAIreportDetails = () => {
        if (assessment_id && jwt) {
            setIsLoading(true);
            showLoader();
            // API call to get AI report details
            axios
                .get(`${endpoint.get_ai_report(assessment_id)}&jwt=${jwt}`, {})
                .then((response) => {
                    setReportData(response.data); // Set report data
                    processReportData(response.data); // Process the data after fetching it
                })
                .catch((error) => {
                    setIsLoading(false);
                    hideLoader();
                    handleTokenError(error); // Handle token error if the request fails
                });
        }
    }

    // Function to process the report data (asynchronous)
    const processReportData = async (reportData: any) => {
        if (reportData) {
            await processAssessmentFiles(reportData); // Wait for assessment files processing
        }
        setIsLoading(false);
        hideLoader(); // Hide loader after processing
    };

    // Function to process assessment files and combine them into a single array
    const processAssessmentFiles = async (reportData: any) => {
        let data: AssessmentFile[] = [
            ...(reportData?.assessmentFilesSectionWiseList[0]?.assessmentFiles || []),
            ...(reportData?.assessmentFilesSectionWiseList[1]?.assessmentFiles || [])
        ];
        setassessmentFiles(data); // Set state after processing
    }

    // Effect to handle scroll event for header change
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Handle scroll to toggle the header state when scrolling past a certain point
    const handleScroll = () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        setIsScrolled(scrollTop > 400); // Adjust the scroll threshold as needed
    };

    // Close the modal and reset form fields
    const handleCloseModal = (event?: any, reason?: any) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Reset the form only if the dialog is closing for a valid reason
            setFormData({
                contact_no: '',
                vin_no: ''
            });
            setErrors({
                contact_no: '',
                vin_no: ''
            });
            setJWTFormCommonError('');
            setIsTokenExpired(false);
        }
    };

    // Handle input change and reset errors for that field
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    // Handle form submission for generating a new token
    const handleGenerateToken = (event: React.FormEvent) => {
        event.preventDefault();
        let formIsValid = true;
        setJWTFormCommonError(""); // Reset common error

        const newErrors: Record<string, string> = {};

        // Validate form fields
        if (!formData.contact_no.trim() && !formData.vin_no.trim()) {
            setJWTFormCommonError("Minimum one field is required.");
            formIsValid = false;
        } else {
            // Validate contact number format
            const contactRegex = /^\d+$/;
            if (formData.contact_no.trim() && !contactRegex.test(formData.contact_no.trim())) {
                newErrors.contact_no = 'Contact no. is not valid.';
                formIsValid = false;
            }

            // Validate VIN number format (17 alphanumeric characters)
            const vinRegex = /^[a-zA-Z0-9]{17}$/;
            if (formData.vin_no.trim() && !vinRegex.test(formData.vin_no.trim())) {
                newErrors.vin_no = 'Vin no. is not valid.';
                formIsValid = false;
            }
        }

        setErrors({ ...errors, ...newErrors }); // Set errors for invalid fields

        // Proceed with generating a new token if the form is valid
        if (formIsValid) {
            generateNewToken(); // Call function to generate a new token
        }
    };

    const navigate = useNavigate(); // Initialize navigate hook for programmatic navigation

    // Function to generate a new JWT token
    const generateNewToken = () => {
        showLoader();
        if (assessment_id && jwt) {
            let url = endpoint.generate_new_token(assessment_id, jwt);

            // Append contact number and VIN number if available
            if (formData?.contact_no?.trim()) {
                url += `&phoneNumber=${formData?.contact_no?.trim()}`;
            }
            if (formData?.vin_no?.trim()) {
                url += `&vinNo=${formData?.vin_no?.trim()}`;
            }

            axios
                .get(url)
                .then((res) => {
                    hideLoader(); // Hide loader when the request succeeds
                    handleCloseModal(); // Close the modal
                    const newJwt: string = res?.data; // Get jwt safely
                    if (newJwt) {
                        // Update the URL with the new JWT token
                        const pathname = window.location.pathname;
                        const searchParams = new URLSearchParams(window.location.search);
                        searchParams.set('jwt', newJwt);
                        const newUrl = `${pathname}?${searchParams.toString()}`;
                        navigate(newUrl, { replace: true }); // Navigate to the updated URL
                        setIsLoading(true);
                        setTimeout(() => {
                            setJWT(newJwt); // Update JWT token
                        }, 0);
                    } else {
                        setIsError("JWT token could not be generated.");
                    }
                })
                .catch((err) => {
                    hideLoader(); // Hide loader on failure
                    let err_data: any = err?.response?.data || {};
                    let err_message: string = err_data?.error_message
                        ? `Error: ${err_data.error_message}`
                        : 'Something went wrong, please contact Helpdesk!';
                    setIsError(err_message);
                    handleCloseModal();
                });
        }
    };

    const { openSnackbar } = useSnackbar(); // Snackbar context for showing messages

    // Handle report download
    const handleDownloadReport = () => {
        if (assessment_id && reportData?.accessToken) {
            showLoader();
            axios
                .patch(`${endpoint.download_ai_report(assessment_id)}`, null, {
                    headers: {
                        Authorization: reportData?.accessToken, // Set the access token in the headers
                    },
                })
                .then(async (response) => {
                    hideLoader();
                    const pdfUrl: string = response?.data?.trim(); // Extract the PDF URL from the response
                    if (pdfUrl) {
                        const response = await fetch(pdfUrl);
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${assessment_id}-${new Date().toISOString()}.pdf`); // Set download attribute with the desired file name
                        document.body.appendChild(link);
                        link.click(); // Trigger the download
                        link.remove(); // Clean up after download
                        window.URL.revokeObjectURL(url); // Clean up the object URL
                    } else {
                        const err_message: string = "Something went wrong, please contact Helpdesk!";
                        openSnackbar(getSnackbarMessage(err_message), "error", 4000); // Show error message
                    }
                })
                .catch((error) => {
                    let err_data: any = error?.response?.data || {};
                    const err_message = err_data?.error_message || "Something went wrong, please contact Helpdesk!";
                    hideLoader();
                    openSnackbar(getSnackbarMessage(err_message), "error", 4000); // Show error message in snackbar
                });
        }
    }

    // Handle token error, set expired state or display error message
    const handleTokenError = (error: any) => {
        if (error?.response && error?.response?.status >= 400 && error?.response?.status < 500) {
            setIsTokenExpired(true); // Set token expired if it's a client-side error
        } else {
            let err_data: any = error?.response?.data || {};
            const err_message = err_data?.error_message || "Something went wrong, please contact Helpdesk!";
            setIsError(err_message);
            handleCloseModal();
        }
    }

    return (
        <>
            {/* Render loading or error message */}
            {!isLoading &&
                <>
                    {isError ?
                        <section className='error_viewer_outer'>
                            <p className="m-0">{isError}</p> {/* Show error message */}
                        </section>
                        :
                        <>
                            {/* Render token generation dialog if token is expired */}
                            {isTokenExpired ?
                                <div className="token_dialog_container">
                                    <Dialog open={isTokenExpired} onClose={handleCloseModal} maxWidth="xs" fullWidth className='token_generate_dialog_outer'>
                                        <DialogTitle className='token_generate_dialog_header'>
                                            <p className="m-0">Generate Token</p>
                                        </DialogTitle>
                                        <DialogContent className="dialog_content_part">
                                            <div className='header_part'>
                                                <p className="m-0 center">Please fill any one field for generating the AI Report</p>
                                            </div>
                                            <form onSubmit={handleGenerateToken}>
                                                {/* Contact number field */}
                                                <div className="mb-2">
                                                    <CustomTextField
                                                        id="contact_no"
                                                        name="contact_no"
                                                        type="text"
                                                        value={formData.contact_no}
                                                        onChange={handleInputChange}
                                                        label="Contact No:"
                                                        fullWidth
                                                        className={`${errors.contact_no ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.contact_no && <p className="m-0 custom_error">{errors.contact_no}</p>}
                                                </div>
                                                {/* VIN number field */}
                                                <div className="mb-2">
                                                    <CustomTextField
                                                        id="vin_no"
                                                        name="vin_no"
                                                        type="text"
                                                        value={formData.vin_no}
                                                        onChange={handleInputChange}
                                                        label="Vin No:"
                                                        fullWidth
                                                        className={`${errors.vin_no ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.vin_no && <p className="m-0 custom_error">{errors.vin_no}</p>}
                                                </div>
                                                {/* Display common error if both fields are empty */}
                                                {tokenFormCommonError && (
                                                    <p className="m-0 custom_error">
                                                        {tokenFormCommonError}
                                                    </p>
                                                )}
                                                {/* Submit button for generating token */}
                                                <CustomButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Generate
                                                </CustomButton>
                                            </form>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                                :
                                <>
                                    {/* Render the report view if data is available */}
                                    {reportData &&
                                        <>
                                            <div className="report_main_container">
                                                {/* Render header section 1 */}
                                                <section className={`report_header1 ${isScrolled ? 'report_header1_scrolled' : 'report_header1_not_scrolled'}`}>
                                                    <EmailReportHeader1 qrCode={reportData?.qrCode} externalAIReportUrl={reportData?.externalAIReportUrl} onDownloadReport={handleDownloadReport} />
                                                </section>
                                                {/* Render header section 2 */}
                                                <section className="report_header2">
                                                    <EmailReportHeader2
                                                        qrCode={reportData?.qrCode}
                                                        reportCreatedDate={reportData?.reportCreatedDate}
                                                        reportCreatedTime={reportData?.reportCreatedTime}
                                                        companyLogoUrl={reportData?.companyLogoUrl}
                                                    />
                                                </section>
                                                <div className="report_details_inner">
                                                    {/* Vehicle info slider */}
                                                    <section className="report_inner_section_devider report_vehicle_info_wise_view">
                                                        <EmailReportVehileInfoSlider reportData={reportData} />
                                                    </section>

                                                    {/* Damage areas side-wise view */}
                                                    {reportData?.sectionWiseDamageAreasList && reportData?.sectionWiseDamageAreasList.length > 0 && (
                                                        <section className="report_inner_section_devider report_side_wise_view">
                                                            <EmailReportCarSideWiseView
                                                                vehicleDamageInfographics={reportData.sectionWiseDamageAreasList
                                                                    .map(item => item.damageAreas || [])
                                                                    .flat()}
                                                            />
                                                        </section>
                                                    )}

                                                    {/* Image details section */}
                                                    {assessmentFiles?.length && (
                                                        <section className="report_inner_section_devider image_details_outer">
                                                            <EmailReportImageDetails reportData={reportData} assessmentFiles={assessmentFiles} isMinorDamage={isMinorDamage} isPinnedDamage={isPinnedDamage} />
                                                        </section>
                                                    )}

                                                    {/* QnA section */}
                                                    {reportData?.answers?.length > 0 && (
                                                        <section className="report_inner_section_devider questionaries_outer">
                                                            <EmailReportQnA QnA={reportData?.answers} />
                                                        </section>
                                                    )}
                                                </div>
                                                {/* Footer section */}
                                                {/* <section className="report_footer">
                                                    <EmailReportFooter />
                                                </section> */}
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}

export default Email_Report;
