import { ReportData } from '../../../../models/AI_Report';  // Importing the data model for the report

// Component for displaying GT & TU details in the email report
const ERVehicleInfoSlideGtTu = ({ reportData }: { reportData: ReportData }) => {
    return (
        <div className="info_body_outer">
            <div className="top_part">
                {/* Display the car info icon */}
                <img
                    alt=""
                    src="/images/email_report/car_info.svg"
                />
                <p className="m-0">
                    {/* Conditional rendering of the section title based on data availability */}
                    {reportData.transunionDetails != null && reportData.gtEstimateDetails != null
                        ? 'GT & TU Details' : reportData.transunionDetails != null && reportData.gtEstimateDetails == null
                            ? 'TU Details' : reportData.transunionDetails == null && reportData.gtEstimateDetails != null
                                ? 'GT Details' : ''}
                </p>
            </div>

            <div className="body_part gt_tu_details_body_part">
                <div className="data_part_container">

                    {/* GT Estimate Details Section */}
                    {reportData.gtEstimateDetails != null && (
                        <>
                            <div className="data_part_outer">
                                <div className="left_part">
                                    <p className="m-0">GT Estimate:</p> {/* Label for GT Estimate */}
                                </div>
                                <div className="right_part">
                                    {/* Display the GT estimate value */}
                                    <p className="m-0">{reportData?.gtEstimateDetails?.gtEstimate}</p>
                                </div>
                            </div>

                            <div className="data_part_outer">
                                <div className="left_part">
                                    <p className="m-0">GT Estimate Value:</p> {/* Label for GT Estimate Value */}
                                </div>
                                <div className="right_part">
                                    {/* Display the GT estimate value or 'NA' if not available */}
                                    <p className="m-0">{reportData?.gtEstimateDetails?.gtEstimateValue || 'NA'}</p>
                                </div>
                            </div>
                        </>
                    )}

                    {/* Divider if both GT and TU details exist */}
                    {reportData.transunionDetails != null && reportData.gtEstimateDetails != null && <hr />}

                    {/* TU Details - Trade Value */}
                    {reportData?.transunionDetails?.vehicleTradeValueValid && (
                        <div className="data_part_outer">
                            <div className="left_part">
                                <p className="m-0">Trade Value:</p> {/* Label for Trade Value */}
                            </div>
                            <div className="right_part">
                                {/* Display the trade value or 'NA' if not available */}
                                <p className="m-0">{reportData?.transunionDetails?.vehicle_trade_value || 'NA'}</p>
                            </div>
                        </div>
                    )}

                    {/* TU Details - Retail Value */}
                    {reportData?.transunionDetails?.vehicleRetailValueValid && (
                        <div className="data_part_outer">
                            <div className="left_part">
                                <p className="m-0">Retail Value:</p> {/* Label for Retail Value */}
                            </div>
                            <div className="right_part">
                                {/* Display the retail value or 'NA' if not available */}
                                <p className="m-0">{reportData?.transunionDetails?.vehicle_retail_value || 'NA'}</p>
                            </div>
                        </div>
                    )}

                    {/* TU Details - Estimated Trade Value if Trade Value is not valid */}
                    {reportData?.transunionDetails?.estimatedVehicleTradeValueValid && !reportData?.transunionDetails?.vehicleTradeValueValid && (
                        <div className="data_part_outer">
                            <div className="left_part">
                                <p className="m-0">Estimated Trade Value:</p> {/* Label for Estimated Trade Value */}
                            </div>
                            <div className="right_part">
                                {/* Display the estimated trade value or 'NA' if not available */}
                                <p className="m-0">{reportData?.transunionDetails?.vehicle_estimated_trade_value || 'NA'}</p>
                            </div>
                        </div>
                    )}

                    {/* TU Details - Estimated Retail Value if Retail Value is not valid */}
                    {reportData?.transunionDetails?.estimatedVehicleRetailValueValid && !reportData?.transunionDetails.vehicleRetailValueValid && (
                        <div className="data_part_outer">
                            <div className="left_part">
                                <p className="m-0">Estimated Retail Value:</p> {/* Label for Estimated Retail Value */}
                            </div>
                            <div className="right_part">
                                {/* Display the estimated retail value or 'NA' if not available */}
                                <p className="m-0">{reportData?.transunionDetails?.vehicle_estimated_retail_value || 'NA'}</p>
                            </div>
                        </div>
                    )}

                    {/* TU Details - New List Value if no other values are available */}
                    {reportData?.transunionDetails?.newListValueValid &&
                        !reportData?.transunionDetails?.vehicleTradeValueValid &&
                        !reportData?.transunionDetails?.estimatedVehicleTradeValueValid &&
                        !reportData?.transunionDetails?.vehicleRetailValueValid &&
                        !reportData?.transunionDetails?.estimatedVehicleRetailValueValid && (
                            <div className="data_part_outer">
                                <div className="left_part">
                                    <p className="m-0">New List Value:</p> {/* Label for New List Value */}
                                </div>
                                <div className="right_part">
                                    {/* Display the new list value or 'NA' if not available */}
                                    <p className="m-0">{reportData?.transunionDetails?.vehicle_new_list_value || 'NA'}</p>
                                </div>
                            </div>
                        )}
                </div>

                {/* Additional Explanation when only the New List Value is available */}
                {reportData?.transunionDetails?.newListValueValid &&
                    !reportData?.transunionDetails?.vehicleTradeValueValid &&
                    !reportData?.transunionDetails?.estimatedVehicleTradeValueValid &&
                    !reportData?.transunionDetails?.vehicleRetailValueValid &&
                    !reportData?.transunionDetails?.estimatedVehicleRetailValueValid && (
                        <div className="full_width_info_body">
                            <img src="/images/email_report/car_info.svg" alt="" />
                            <p className="m-0">
                                List price is the cost of the car in the registration year when it was first listed as new.
                                In case you are viewing only the List price, then TU doesn't have the actual price & estimate
                                price for the particular vehicle data.
                            </p> {/* Explanation about the list price */}
                        </div>
                    )}
            </div>
        </div>
    )
}

export default ERVehicleInfoSlideGtTu;
